import sys_cnf from '../../../system/config/config.js';
import sys_app_cnf from '../../../system/config/app-config.js';
import sysColor from '../../../system/config/color.js';
import React, { useState, useEffect, Fragment } from 'react';
import capStr from '../../../system/method/capStr.js';
import { useNavigate} from 'react-router-dom';
import getCurrentLine from 'get-current-line';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { v4 as uuid } from 'uuid'
import 'react-phone-number-input/style.css'
import getUser from '../../../api/auth/getUser.js';
import BarLoader from "react-spinners/BarLoader";

const RoleCreate = () => {
    var GetUser = getUser();
    const [permission, setPermission] = useState("");
    const [role, setRole] = useState("");
    const navigate = useNavigate();
   
    useEffect(() => {
        document.title = 'Create New Role - '+sys_app_cnf.site_name;
    }, []);

    useEffect(() => {
        getPermission();
    }, []);

    const getPermission = async () => {
        const sys_config = sys_cnf();
        setPermission({'status':-1,'message':'Loading...','data':{},'code':400});
        var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
        var sys_error = sys_respose.status == 0?0:0;
        if(sys_error == 0){
            var dataResponse;
            await axios.get(`${sys_config.api_req_domain}/api/admin/store/permission/search`, {
                headers: {
                    'Authorization': `Bearer ${GetUser.data.lid}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(function (response) {
                dataResponse = response.data;
            })
            .catch(function (error) {
                sys_error = 1;
                sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':error.response.data.code,'ev':getCurrentLine().line}
                setPermission(sys_respose);
            });
        }
        
        if(sys_error == 0){
            sys_respose = {'status':dataResponse.status,'message':dataResponse.message,'data':dataResponse.data,'code':dataResponse.code};
            setPermission(sys_respose);
        }
    }

    const createRole = async () => {
        const sys_config = sys_cnf();
        var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
        var sys_error = sys_respose.status == 0?0:0;
        const toastId = uuid();
        toast.loading("Role creating...", {toastId: toastId});

        try {
            if(sys_error == 0){
                var GetSelectedPermission = getSelectedPermission();
                var dataResponse;
                await axios.post(`${sys_config.api_req_domain}/api/admin/store/role/create`, {
                    store_id:GetUser.data.store_id,
                    role:role.toLowerCase(),
                    permissions:GetSelectedPermission.join(','),
                },{
                    headers: {
                        'Authorization': `Bearer ${GetUser.data.lid}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                  })
                  .then(function (response) {
                    dataResponse = response.data;
                  })
                  .catch(function (error) {
                    sys_error = 1;
                    sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':400,'ev':getCurrentLine().line}
                  });
            }
            
            if(sys_error == 0){
                if(dataResponse.status == 1){
                    sys_respose = {'status':1,'message':dataResponse.message,'data':{},'code':200};
                }else{
                    sys_error = 1;
                    sys_respose = {'status':0,'message':'Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
                }
            }

            if(sys_error == 0){
                toast.update(toastId, { render: sys_respose.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
                navigate(`${process.env.PUBLIC_URL}/role/list`);
            }else{
                toast.update(toastId, { render: sys_respose.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }

        } catch (error) {
            toast.update(toastId, { render: "Oops! Something went wrong", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
        }
    }

    function getSelectedPermission(){
        var selectedPermission = [];
        var checkboxes = document.getElementsByName('permission');
        for(var i=0, n=checkboxes.length;i<n;i++) {
            if(checkboxes[i].checked == true){
                if(checkboxes[i].value == 'admin_admin_all'){
                    selectedPermission = [checkboxes[i].value];
                    break;
                }else{
                    selectedPermission.push(checkboxes[i].value);
                }
            }
        }
        return selectedPermission;
    }

    const HendlePermission = (event) => {
        if(event.target.value != 'admin_admin_all'){
            if (event.target.checked === true){
                var checkboxes = document.getElementsByName('permission');
                var allCheckboxChecked = true;
                for(var i=0, n=checkboxes.length;i<n;i++) {
                    if(checkboxes[i].value != 'admin_admin_all'){
                        if(checkboxes[i].checked != true){
                            allCheckboxChecked = false;
                            break;
                        }
                    }
                }

                if(allCheckboxChecked == true){
                    document.getElementById("admin_admin_all").checked = true;
                }
            }else{
                document.getElementById("admin_admin_all").checked = false;
            }
        }else{
            var checkboxesState = false;
            if (event.target.checked === true){
                checkboxesState = true;
            }

            var checkboxes = document.getElementsByName('permission');
            for(var i=0, n=checkboxes.length;i<n;i++) {
                checkboxes[i].checked = checkboxesState;
            }
        }
    }

    const cssPermission = {
        display: 'grid!important',
        gridTemplateColumns:'100px 1fr'
    }

    return (
        <Fragment>
            {/* <Breadcrumb title="New Store" parent="Store" /> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 p-0">
                        <div className="card mt-4 p-4">
                            <h4 className="text-left">Create New Role</h4>
                            <div className="theme-form" style={{"marginTop":"30px"}}>
                                {(permission != null && permission.status == 1) &&
                                    <>
                                        <div className="row">
                                            <div className="form-group col-sm-12 pr-3">
                                                <label className="col-form-label">Role*</label>
                                                <input className="form-control" type="text" placeholder="Store Name" value={role} onChange={e => setRole(e.target.value)} />
                                            </div>
                                            <div className="form-group col-sm-12 pl-3">
                                                <div className="card-body animate-chk">
                                                    {permission.data.map((data, index) => (
                                                        <div key={index}>
                                                            {(index > 0 && data.new_lable == 1) && <div style={{marginTop:'20px'}}></div>}
                                                            <label className="d-grid" style={cssPermission} htmlFor={data.permission_id}>
                                                                <div>
                                                                    {(data.new_lable == 1) ? data.permission_name : ''}
                                                                </div>
                                                                <div>
                                                                    <input className="checkbox_animated" onClick={(e) => HendlePermission(e)} name="permission" value={data.permission_id} id={data.permission_id} type="checkbox" />
                                                                    {capStr(data.capability)}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group form-row mt-3 mb-0 d-grid w-150">
                                            <button className="btn btn-primary" type="button" onClick={() => createRole()}>Create Role</button>
                                        </div>
                                    </>
                                }

                                {(permission != null && permission.status == 0) &&
                                    <div className="form-group col-sm-12 pr-3">
                                        <label className="col-form-label">{permission.message}</label>
                                    </div>
                                }

                                {(permission == null || permission.status == -1) &&
                                    <div className="col-sm-12 pr-3">
                                        <BarLoader
                                            color={sysColor.primary}
                                            loading={true}
                                            width={'100%'}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default RoleCreate;
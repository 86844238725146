import React, { Fragment, useEffect, useState , useRef} from 'react';
import { FiPlus, FiCheck, FiMessageSquare, FiLogOut, FiMenu, FiSend } from "react-icons/fi";
import { MdDeleteOutline, MdClose } from "react-icons/md"
import sysColor from "../../../system/config/color.js";
import sys_app_cnf from '../../../system/config/app-config.js';
import sys_cnf from "../../../system/config/config.js";
import requestAPI from "../../../api/service/text2img/v1.js";
import historyAPI from "../../../api/service/text2img/history.js";
import logOutUserAPI from "../../../api/auth/logOutUser.js";
import clearHistoryAPI from "../../../api/service/text2img/clearHistory.js";
import deleteHistoryByGroupIdAPI from "../../../api/service/text2img/deleteHistoryByGroupId.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuid } from "uuid";
import "./v1.css";
import BarLoader from "react-spinners/BarLoader";
import BeatLoader from "react-spinners/BeatLoader";
import ClipLoader from "react-spinners/ClipLoader";
import cap1stLetter from '../../../system/method/cap1stLetter.js';
import { Link, useNavigate, useParams} from 'react-router-dom';
import Avatar from 'react-avatar';
import getUser from '../../../api/auth/getUser.js';
import 'react-alert-confirm/lib/style.css';
import AlertConfirm from 'react-alert-confirm';
import { Typewriter, useTypewriter, Cursor } from 'react-simple-typewriter'

const V1 = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    var groupIdFromUrl;
    if(id != null && id.length >= 10){
        groupIdFromUrl = id;
    }
    
    const sys_config = sys_cnf();
    const [groupId, setGroupId] = useState(uuid());
    var GetUser = getUser();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        document.title = sys_config.site_config.name;
    }, []);
    
    useEffect(() => {
        
        window.addEventListener('resize', handelResize);
        return () => window.removeEventListener('resize', handelResize);
    }, []);

    const handelResize = ()=>{
        if(window.innerWidth <= 766){
            // continue
        }else{
            toogleMobileMenu(1);
        }
    };

    const handelSideBar = ()=>{
        if(window.innerWidth <= 766){
            toogleMobileMenu(0);
        }else{
            toogleMobileMenu(1);
        }
    };
    
    const userFirstLatter = (GetUser.status == 1 ? GetUser.data.user.fname : 'User')
          .split(' ')
          .map((word) => word.charAt(0))
          .join('');

    const [getRecentHistory, setRecentHistory] = useState({
        status: -1,
        message: "Success",
        data: [],
        code: 400,
    });

    const [getHistory, setHistory] = useState({
        status: 1,
        message: "Success",
        data: [],
        code: 400,
    });

    const [getResponse, setResponse] = useState({
        status: 0,
        message: "Success",
        data: "",
        code: 400,
    });

    const [clearHistory, setClearHistory] = useState({
        status: 1,
        message: "Success",
        data: [],
        code: 400,
    });

    const [deleteChat, setDeleteChat] = useState({
        status: 1,
        message: "Success",
        group_id:'',
        data: [],
        code: 400,
    });

    useEffect(async () => {
        setRecentHistory({ status: -1, message: "Loading", data: [], code: 400 });
        try {
            var response = await historyAPI();
            if (response.status == 1) {
                setRecentHistory({ status: 1, message: "Success", data: response.data, code: 400,});
                setTimeout(() => {
                    window.scrollTo(0,document.body.scrollHeight);
                }, 1500);
            } else {
                setRecentHistory({ status: 1, message: "Success", data: [], code: 400 });
            }
        } catch (error) {
            setRecentHistory({ status: 1, message: "Success", data: [], code: 400 });
        }
    }, [groupIdFromUrl]);

    useEffect(async () => {
        handelSideBar();
        if (groupIdFromUrl != null && groupIdFromUrl.length > 0) {
          setGroupId(groupIdFromUrl);
          setHistory({ status: -1, message: "Loading", data: [], code: 400 });
          try {
            var response = await historyAPI(groupIdFromUrl);
            if (response.status == 1) {
              setHistory({
                status: 1,
                message: "Success",
                data: response.data.reverse(),
                code: 400,
              });
            }else{
              setHistory({ status: 1, message: "Success", data: [], code: 400 });
            }
          } catch (error) {
            setHistory({ status: 1, message: "Success", data: [], code: 400 });
          }
        }else{
            setHistory({ status: 1, message: "Success", data: [], code: 400 });
        }
        window.scrollTo(0,document.body.scrollHeight);
    }, [groupIdFromUrl]);

    function utcToLocalDateTime(utcDateTime, IsDate = 1) {
        const options = {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
        var localDateTime = new Date(utcDateTime).toLocaleString('en-US', options).replace(' at',',');
        localDateTime = localDateTime.replace(' at',',')
        const date = localDateTime.split(', ')[0]+' '+localDateTime.split(', ')[1];
        const time = localDateTime.split(', ')[2];
        const [month, day, year] = date.split(' ');
        const formattedDate = `${day} ${month} ${year}`;
        const formattedTime = time.toUpperCase();
      
        return IsDate == 1 ? `${formattedDate}, ${formattedTime}`:formattedTime;
    }

    function currentUtcDateTime() {
        const currentDateTime = new Date().toISOString();
        return currentDateTime;
    }
    
    const getApiResponse = async (query) => {
        var response = { status: -1, message: "Loading", data: "", code: 400 };
        setResponse({ status: -1, message: "Loading", data: "", code: 400 });
        try {
            response = await requestAPI(query, groupId);
            setResponse({
                status: response.status,
                message: response.message,
                data: response.data,
                code: response.code,
            });
            if (response.status != 1) {
                toast.error(response.message, {
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: false,
                pauseOnFocusLoss: false,
                });
            }
        } catch (error) {
            toast.error("Oops! Something went wrong", {
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: false,
                pauseOnFocusLoss: false,
            });
            response = {
                status: 0,
                message: "Oops! Something went wrong",
                data: "",
                code: 400,
            };
            setResponse(response);
        }
        return response;
    };

    const clearChatHistory = async () => {
        if(clearHistory.status != -1){
            setClearHistory({ status: -1, message: "Loading", data: "", code: 400 });
            var response;
            try {
                response = await clearHistoryAPI();
                setClearHistory({
                    status: response.status,
                    message: response.message,
                    data: response.data,
                    code: response.code,
                });
                if (response.status == 1) {
                    setRecentHistory({ status: 1, message: "Success", data: [], code: 400 });
                    navigate(`${sys_app_cnf.dashboard_url}/text2img/${uuid()}`);
                }
            } catch (error) {
                response = {
                    status: 0,
                    message: "Oops! Something went wrong",
                    data: "",
                    code: 400,
                };
                setClearHistory(response);
            }
            handelSideBar();
            return response;
        }
    };

    const deleteChatHistory = async (group_id) => {
        if(deleteChat.status != -1){
            setDeleteChat({ status: -1, message: "Loading", group_id:group_id, data: "", code: 400 });
            var response;
            try {
                response = await deleteHistoryByGroupIdAPI(group_id);
                setDeleteChat({
                    status: response.status,
                    message: response.message,
                    group_id:group_id,
                    data: response.data,
                    code: response.code,
                });
                if (response.status == 1) {
                    var tmpRecentHistory = [];
                    for (var i = 0; i < getRecentHistory.data.length; i++) {
                        if(getRecentHistory.data[i].group_id != group_id){
                            tmpRecentHistory = [...tmpRecentHistory, getRecentHistory.data[i]];
                        }
                    }
                    setRecentHistory({ status: getRecentHistory.status, message: getRecentHistory.message, data: tmpRecentHistory, code: getRecentHistory.code });
                    navigate(`${sys_app_cnf.dashboard_url}/text2img/${uuid()}`);
                }
            } catch (error) {
                response = {
                    status: 0,
                    message: "Oops! Something went wrong",
                    group_id:group_id,
                    data: "",
                    code: 400,
                };
                setDeleteChat(response);
            }
            handelSideBar();
            return response;
        }
    };
    const [messageInput, setMessageInput] = useState('');

    const handleMessageChange = (message) => {
        setMessageInput(message)
    }

    const toogleMobileMenu = (value) => {
        var sideBarMain = document.querySelector(".sideBarMain");
        var sideBarMainDisplay = getComputedStyle(sideBarMain).display
        var chatAreaMain = document.querySelector(".chatAreaMain");
        if(window.innerWidth > 799){
            sideBarMain.style.display = "block";
            chatAreaMain.style.display = "block";
        }else if(value === 1){
            sideBarMain.style.display = "block";
            chatAreaMain.style.display = "none";
        }else if(value === 0){
            sideBarMain.style.display = "none";
            chatAreaMain.style.display = "block";
        }else if(sideBarMainDisplay == 'block'){
            sideBarMain.style.display = "none";
            chatAreaMain.style.display = "block";
        }else{
            sideBarMain.style.display = "block";
            chatAreaMain.style.display = "none";
        }
    }

    const handelLogOutUser = () => {
        AlertConfirm({
            title: 'Do you want to logout?',
            onOk: () => {
                var response = logOutUserAPI();
                if(response.status == 1){
                    navigate(`${sys_app_cnf.dashboard_url}/login`);
                }
                setVisible(false);
            },
            onCancel: () => {
                setVisible(false);
            }
        });
    }

    const handleMessagePress = async(e) => {
        if (e.key === "Enter" || e === "send") {
            if(getResponse.status != -1){
                var tmpMessageInput = messageInput;
                window.scrollTo(0,document.body.scrollHeight);
                var tmpGetHistoryData = getHistory.data;
                var tmpGetHistoryDataAppend = [...tmpGetHistoryData,{'status':2,'uid':uuid(),'query':messageInput,'response':'','group_id':groupId,'rdate':currentUtcDateTime()}];
                var tmpGetHistoryDataFinal = {'status':getHistory.status,'data':tmpGetHistoryDataAppend,'code':getHistory.code}
                setHistory(tmpGetHistoryDataFinal);
                setMessageInput('');
                var GetApiResponse = await getApiResponse(messageInput);
                tmpGetHistoryDataAppend = [...tmpGetHistoryData,{'status':1,'uid':uuid(),'query':tmpMessageInput,'image': GetApiResponse.status == 1 ? GetApiResponse.data:GetApiResponse.message,'group_id':groupId,'rdate':currentUtcDateTime()}];;
                tmpGetHistoryDataFinal = {'status':getHistory.status,'data':tmpGetHistoryDataAppend,'code':getHistory.code}
                setHistory(tmpGetHistoryDataFinal);
                setTimeout(() => {
                    window.scrollTo(0,document.body.scrollHeight);
                }, 1000);
                
            }
        }
    }
    
    return (
        <Fragment>
            <div class="aptGPT">
                <div className="container-fluid">
                    <div className="row">
                        <div id='sideBarMain' className="col sideBarMain call-chat-sidebar col-sm-12 p-0">
                            <div className="sideBar card card-body chat-body chat-box chat-left-aside">
                                <div className="sideBarHeader">
                                    <Link className="newChatButton mb-0" to={`${process.env.PUBLIC_URL}/text2img/${uuid()}`} style={{
                                            color: '#fff'
                                        }} onClick={()=>{
                                            handelSideBar();
                                            setResponse({status: 0, message: "Success", data: "", code: 400, });
                                        }}>
                                        <FiPlus className='icon' />
                                        <div>New Chat</div>
                                    </Link>
                                    <div className="sideBarHeaderCloseButton" onClick={()=>toogleMobileMenu(0)}>
                                        <MdClose className='icon' />
                                    </div>
                                </div>
                                <div className="chatRecentSearch custom-scrollbar">
                                        {getRecentHistory.status == 1 && getRecentHistory.data.length > 0?
                                        <ul className="chatRecentSearchContainer">
                                            {getRecentHistory.data.map((item, i) => {
                                                return (
                                                    <Link to={`${process.env.PUBLIC_URL}/text2img/${item.group_id}`} style={{
                                                    color: '#fff'
                                                    }} className="chatRecentSearchBox" onClick={()=>{
                                                        handelSideBar();
                                                        setResponse({status: 0, message: "Success", data: "", code: 400, });
                                                        }}>
                                                        <FiMessageSquare style={{marginTop:'3px',fontSize:18,marginRight:'10px'}} />
                                                        <p>{cap1stLetter(item.query)}</p>
                                                        {deleteChat.status == -1 && deleteChat.group_id == item.group_id?
                                                            <ClipLoader
                                                                color={sysColor.white}
                                                                loading={true}
                                                                size={18}
                                                                aria-label="Loading Spinner"
                                                                data-testid="loader"
                                                            />
                                                            :<MdDeleteOutline className='chatRecentSearchDeleteIcon' onClick={()=>deleteChatHistory(item.group_id)} />
                                                        }
                                                    </Link>
                                                );
                                            })
                                            }
                                        </ul>
                                        :  getRecentHistory.status == -1?
                                            <div className="chatRecentLoadingBox">
                                                <ClipLoader
                                                    color={sysColor.white}
                                                    loading={true}
                                                    size={24}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            </div>
                                        :
                                            <div className="text-white">
                                                No history found
                                            </div>
                                        }
                                        
                                </div>
                                <div className='sideBarFooter'>
                                    <div className='chatClearConversations' onClick={() =>{
                                        AlertConfirm({
                                            title: 'Do you want to clear all chat history?',
                                            onOk: () => {
                                                clearChatHistory();
                                            },
                                            onCancel: () => {
                                                setVisible(false);
                                            }
                                          });
                                    }}>
                                        {clearHistory.status == -1?<label className="mt-0 mb-0"
                                            style={{
                                                marginRight:'5px',
                                            }}
                                        >
                                                <ClipLoader
                                                    color={sysColor.white}
                                                    loading={true}
                                                    size={12}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            </label>:<FiCheck className="icon"/>
                                        }
                                        <p className='m-0'>
                                            Clear conversations
                                        </p>
                                    </div>
                                    <div className='sideBarLogout' onClick={()=>handelLogOutUser()}>
                                        <FiLogOut className="icon" />
                                        <p className='m-0'>Logout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col chatAreaMain call-chat-body h-100v">
                            <div className="card m-0">
                                <div className="card-body p-0">
                                    <div className="row chat-box">
                                        <div className="col pe-0 chat-right-aside">
                                            <div className="chat">
                                                <div className="chat-header clearfix">
                                                    <div className='logo_header'>
                                                        {sys_config.site_config.logo != null ? <img src={sys_config.site_config.logo} className="" alt="" />
                                                        :<div className="about">
                                                            <div className="name">
                                                                {sys_config.site_config.name}
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    <div className="mobileMenuContainer">
                                                        <div className="mobileMenu">
                                                            <FiMenu style={{fontSize:'22px'}} onClick={()=>toogleMobileMenu(1)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="chat-history chat-msg-box custom-scrollbar">
                                                    <ul className='chatHistoryMessageBox'>
                                                        
                                                        {getHistory.status == 1 && getHistory.data.length > 0 ? getHistory.data.map((item, index) => {
                                                            return (
                                                                <>
                                                                    {index == 0 && <li key={'int_message'} className="clearfix">
                                                                        <div className={`message my-message`}>
                                                                            <img src={sys_config.site_config.chatLogo}
                                                                                className={`float-start chat-user-img`} style={{marginTop: '-34px!important', marginBottom:'-1px!important'}} alt="" />
                                                                                <label>
                                                                                    {sys_config.aptGptAboutMe}
                                                                                </label>
                                                                        </div>
                                                                    </li>}
                                                                    <li key={item.uid+'_query'} className="clearfix">
                                                                        <div className={`message my-message`}>
                                                                            <Avatar name={userFirstLatter} className={`chat-user-img chat-user-img-user`} size="40" round={true} />
                                                                            <div className="message-data-user">
                                                                                <span className="message-data-time ml-2">{utcToLocalDateTime(item.rdate)}</span>
                                                                            </div>
                                                                            {item.status == 2?<Typewriter
                                                                                words={[item.query]}
                                                                                loop={1}
                                                                                cursor={false}
                                                                                cursorStyle='_'
                                                                                typeSpeed={5}
                                                                                deleteSpeed={50}
                                                                                delaySpeed={1000}
                                                                                onLoopDone={()=>{}}
                                                                                onType={()=>{
                                                                                    window.scrollTo(0,document.body.scrollHeight);
                                                                                }}
                                                                          />:<div style={{
                                                                            whiteSpace: 'break-spaces!important'
                                                                          }}>{item.query}</div>}
                                                                        </div>
                                                                    </li>
                                                                    <li key={index+'_response'} className="clearfix">
                                                                        <div className={`message my-message`}>
                                                                            <img src={sys_config.site_config.chatLogo}
                                                                                className={`float-start chat-user-img`} alt="" />
                                                                            <div className="message-data">
                                                                                <span className="message-data-time ml-2">{utcToLocalDateTime(item.rdate,0)}</span>
                                                                            </div>
                                                                            {item.status == 2? <div style={{
                                                                                marginTop: '15px'
                                                                            }} >
                                                                                <BeatLoader
                                                                                    color={sysColor.primary}
                                                                                    loading={true}
                                                                                    width={'100%'}
                                                                                    aria-label="Loading Spinner"
                                                                                    data-testid="loader"
                                                                                />
                                                                                </div>
                                                                            :<div>
                                                                                <img src={item.image} alt="" />
                                                                            </div>}
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            );
                                                        }) : 
                                                            <>
                                                                <li key={'init_loading'} className="clearfix">
                                                                    <div className={`message my-message`}>
                                                                        <img src={sys_config.site_config.chatLogo}
                                                                            className={`float-start chat-user-img`} alt="" />
                                                                        <div className="message-data text-end">
                                                                            <span className="message-data-time ml-2">{getHistory.status == - 1? '':'Now' }</span>
                                                                        </div>
                                                                        {sys_config.aptGptAboutMe}
                                                                    </div>
                                                                </li>
                                                                {getHistory.status == - 1?
                                                                    <div className='chatHistoryLoading'>
                                                                        <BarLoader
                                                                            color={sysColor.primary}
                                                                            loading={true}
                                                                            width={'100%'}
                                                                            aria-label="Loading Spinner"
                                                                            data-testid="loader"
                                                                        />
                                                                    </div>:<></>
                                                                }
                                                            </>
                                                        }
                                                    </ul>

                                                </div>
                                                <div className="chat-message clearfix">
                                                    <div className="row">
                                                        <div className="chatMessageBox col-xl-12 d-flex">
                                                            {/* <div className="iconBox smiley-box bg-primary">
                                                                <div className="picker" onClick={() => toggleEmojiPicker()}><img src={require('../../../assets/images/smiley.png')} className="" alt="" /></div>
                                                            </div> */}
                                                            <div className="input-group text-box">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-txt-bx"
                                                                    placeholder="Type a message......"
                                                                    value={messageInput}
                                                                    onKeyPress={(e) => handleMessagePress(e)}
                                                                    onChange={(e) => handleMessageChange(e.target.value)}
                                                                />
                                                                <div className="input-group-append">
                                                                    <div className="sendButton" onClick={() => handleMessagePress('send')}>
                                                                        {getResponse.status != -1?
                                                                            <FiSend className='icon' style={{color: getResponse.status != -1 && messageInput != null && messageInput.length > 0 ?sysColor.primary: '#8e8ea0'}} />
                                                                            :<div style={{marginTop: '5px'}}><BeatLoader
                                                                                color={sysColor.primary}
                                                                                loading={true}
                                                                                size={5}
                                                                                aria-label="Loading Spinner"
                                                                                data-testid="loader"
                                                                            /></div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer newestOnTop pauseOnFocusLoss={false} />
        </Fragment>
    )
}
export default V1
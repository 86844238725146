import sys_cnf from '../../system/config/config.js';
import sys_app_cnf from '../../system/config/app-config.js';
import signupInitAPI from '../../api/auth/signupInit.js';
import signupAPI from '../../api/auth/signup.js';
import getUser from '../../api/auth/getUser.js';
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuid } from 'uuid'
import 'react-phone-number-input/style.css'
import sysColor from "../../system/config/color.js";

const Signup = () => {
    useEffect(() => {
        document.title = sys_config.site_config.name;
    }, []);

    const sys_config = sys_cnf();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [otpScreen, setOtpScreen] = useState(0);
    const [otpSentOnEmail, setOtpSentOnEmail] = useState(0);
    const [emailHidden, setEmailHidden] = useState("");
    const [emailOtp, setEmailOtp] = useState("");
    const [loginChecked, setLoginChecked] = useState(0);
    const [signupToken, setSignupToken] = useState("");

    const signupInit = async () => {
        const toastId = uuid();
        toast.loading("Please wait...", {toastId: toastId});

        try {
            var name_list = name.split(' ');

            var signupInit = await signupInitAPI(name_list.length > 0?name_list[0]:'', name_list.length > 1?name_list[1]:'.',email,password,password);
            if(signupInit.status == 1){
                if(signupInit.data.otp_sent_on.hasOwnProperty("email")){
                    setOtpSentOnEmail(1);
                    setEmailHidden(signupInit.data.otp_sent_on.email);
                }
                setSignupToken(signupInit.data.token);
                setOtpScreen(1);
                toast.update(toastId, { render: signupInit.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }else{
                toast.update(toastId, { render: signupInit.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }
        } catch (error) {
            toast.update(toastId, { render: "Oops! Something went wrong", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
        }
    }

    const accountActivation = async () => {
        const toastId = uuid();
        toast.loading("Please wait...", {toastId: toastId});

        try {
            var signup = await signupAPI(emailOtp, signupToken);
            if(signup.status == 1){
                toast.update(toastId, { render: signup.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
                navigate(`${sys_app_cnf.dashboard_url}/chat`);
            }else{
                toast.update(toastId, { render: signup.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }

        } catch (error) {
            toast.update(toastId, { render: "Oops! Something went wrong", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
        }
    }

    const backToSignup = async() => {
        setOtpScreen(0);
        setOtpSentOnEmail(0);
    }

    useEffect(() => {
        var GetUser = getUser();
        if(GetUser.status == 1){
            navigate(`${sys_app_cnf.dashboard_url}/chat`);
        }
    },[navigate]);

    if(loginChecked == 0){
        setLoginChecked(1);
    }

    return (
        <Fragment>
            <div className="page-wrapper" style={{backgroundColor: '#fff'}}>
                <div className="container-fluid">
                    {(otpScreen != 1) &&
                        <div className="authentication-main" style={{backgroundColor: '#fff'}}>
                            <div className="row">
                                <div className="col-sm-12 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            {sys_config.site_config.logo != null ?
                                            <div className="text-center">
                                                <img src={sys_config.site_config.logo} alt="" style={{maxWidth: '300px', width: 'auto', maxHeight:'50px'}} />
                                            </div>:
                                            <></>
                                            }
                                            <div className="card mt-4 p-4">
                                                <h4 className="text-center">Signup</h4>
                                                <h6 className="text-center">{"Enter your Email and Password"}</h6>
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Name</label>
                                                        <input className="form-control" type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Email</label>
                                                        <input className="form-control" type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required="" />
                                                    </div>
                                                    <div className="form-group" style={{"marginTop":"0px"}}>
                                                        <label className="col-form-label">Password</label>
                                                        <input className="form-control focus" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" required="" />
                                                    </div>
                                                    <div className="p-0">
                                                        <Link to="/login" style={{color: sysColor.primary}}><span className='text-muted'>Have an account? </span>Login</Link>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn" style={{backgroundColor: sysColor.primary, color: '#fff'}} type="button" onClick={() => signupInit()}>Signup</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {(otpScreen == 1) &&
                    <div className="authentication-main" style={{backgroundColor: '#fff'}}>
                            <div className="row">
                                <div className="col-sm-12 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            {sys_config.site_config.logo != null ?
                                            <div className="text-center">
                                                <img src={sys_config.site_config.logo} alt="" style={{maxWidth: '300px', width: 'auto', maxHeight:'50px'}} />
                                            </div>:
                                            <></>
                                            }
                                            <div className="card mt-4 p-4">
                                                <h4 className="text-center">OTP</h4>
                                                <h6 className="text-center">{"Enter your Email and Password"}</h6>
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Email OTP</label>
                                                        <input className="form-control" type="number" value={emailOtp} onChange={e => setEmailOtp(e.target.value)} placeholder="Email OTP" />
                                                        <div className="col-form-label pt-0" style={{"fontSize":"10px"}}>OTP sent on {emailHidden}</div>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn" style={{backgroundColor: sysColor.primary, color: '#fff'}} type="button" onClick={() => accountActivation()}>Account Activation</button>
                                                    </div>
                                                    <div className="p-0" style={{"marginTop":"15px"}}>
                                                        <button className="btn d-inline-block p-0" type="button" onClick={() => backToSignup()}>
                                                            <div className='' style={{color: sysColor.primary}}>Back to signup?</div>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <ToastContainer newestOnTop pauseOnFocusLoss={false} />
                </div>
            </div>
        </Fragment>
    );
};

export default Signup;
import getCurrentLine from 'get-current-line';
import userImage from '../../assets/images/system/user.png';

let saveUserDetail = async function(sys_config, user={}) {
    var sys_error = 0;
    var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
    try {
        if(sys_error == 0){
            var fname = user.fname;
            var lname = user.lname;
            var email = user.email;
            var uid = user.uid;
            var token = user.token;
        }

        if(sys_error == 0){
            if (fname && fname != null && fname.length > 0) {
                // continue
            }else{
                sys_error = 1;
                sys_respose = {'status':0,'message':'Unauthorized User','data':{},'code':400,'ev':getCurrentLine().line};
            }
        }

        if(sys_error == 0){
            if (lname && lname != null && lname.length > 0) {
                // continue
            }else{
                sys_error = 1;
                sys_respose = {'status':0,'message':'Unauthorized User','data':{},'code':400,'ev':getCurrentLine().line};
            }
        }

        if(sys_error == 0){
            if (email && email != null && email.length > 0) {
                // continue
            }else{
                sys_error = 1;
                sys_respose = {'status':0,'message':'Unauthorized User','data':{},'code':400,'ev':getCurrentLine().line};
            }
        }

        if(sys_error == 0){
            if (uid && uid != null && uid.length > 0) {
                // continue
            }else{
                sys_error = 1;
                sys_respose = {'status':0,'message':'Unauthorized User','data':{},'code':400,'ev':getCurrentLine().line};
            }
        }

        if(sys_error == 0){
            if (token && token != null && token.length > 0) {
                // continue
            }else{
                sys_error = 1;
                sys_respose = {'status':0,'message':'Unauthorized User','data':{},'code':400,'ev':getCurrentLine().line};
            }
        }

        if(sys_error == 0){
            try {
                localStorage.setItem("user",JSON.stringify({
                    email:email,
                    fname:fname,
                    lname:lname,
                    uid:uid,
                    token:token,
                }));

                localStorage.setItem("lid",token);
                localStorage.setItem("uid",uid);

                sys_respose = {'status':1,'message':'User detail saved','data':{},'code':200};
            }catch (error) {
                sys_error = 1;
                sys_respose = {'status':0,'message':'Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
            }
        }

    }catch (error) {
        sys_error = 1;
        sys_respose = {'status':0,'message':'Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
    }

    return sys_respose;
};

export default saveUserDetail;
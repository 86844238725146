// import getUserByToken from '../api/auth/getUserByToken.js';
// import logOutUser from '../api/auth/logOutUser.js';
import React, { useEffect } from 'react';
import { Navigate, useNavigate, Outlet } from 'react-router-dom';
import getUser from '../api/auth/getUser.js';
// import { firebase_app } from '../data/config';
// import { authHeader, handleResponse } from '../services/fack.backend';

const PrivateRoute = () => {
    const navigate = useNavigate();
    const GetUser = getUser();
    // const [currentUser, setCurrentUser] = useState(false);
    // const [authenticated,setAuthenticated] = useState(false)
    const jwt_token = localStorage.getItem('lid');
    const uid = localStorage.getItem('uid');

    useEffect(() => {
        const abortController = new AbortController();
        // const requestOptions = { method: 'GET', headers: authHeader() };
        // getUserByToken(jwt_token).then((response)=> {
        //     if(response.status != 1){
        //         logOutUser();
        //         navigate(`${process.env.PUBLIC_URL}/login`);
        //     }
        // });
        
        // fetch('/users', requestOptions).then(handleResponse)
        // firebase_app.auth().onAuthStateChanged(setCurrentUser);
        // setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
      
        return function cleanup() {
            abortController.abort();
        }
    }, [navigate,jwt_token]);

    return (
        GetUser.status == 1  ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/login`} />);
}

export default PrivateRoute;


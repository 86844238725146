import moment from "moment";
import sys_app_cnf from "./app-config";
import url from "url";
import tld from "tldjs";

function isValidUrl(str) {
  try {
    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
}

function getMainDomain(text) {
  const parsedUrl = url.parse(text);
  const hostname = parsedUrl.hostname || text;
  return tld.getDomain(hostname);
}

function getHostnameFromUrl(url) {
  try {
    const parsedUrl = new URL(url);
    let hostname = parsedUrl.hostname;
    if (hostname.startsWith("www.")) {
      hostname = hostname.substring(4);
    }
    return hostname;
  } catch (error) {
    return "";
  }
}

function sys_config() {
  var req_protocol = window.location.protocol;
  var req_hostname = window.location.hostname;
  var req_domain = `${req_protocol}//${req_hostname}`;
  var partner_id = "";

  console.log("req_hostname", req_hostname);
  if (isValidUrl(req_domain)) {
    partner_id = getHostnameFromUrl(req_domain);
    if (!Object.keys(sys_app_cnf.site_config).includes(partner_id)) {
      partner_id = getMainDomain(req_hostname);
    }
  }

  var sys_config_data = {
    // api_req_domain: `http://localhost:8083`,
    api_req_domain: ` https://api.bharatgpt.io`,
    req_protocol: req_protocol,
    req_hostname: req_hostname,
    req_domain: req_domain,
    secure_req_domain: `https://${req_hostname}`,
    unsecure_req_domain: `http://${req_hostname}`,
    current_date_time: moment().format("YYYY-MM-DD H:m:s"),
    current_date: moment().format("YYYY-MM-DD"),
    current_time: moment().format("H:m:s"),
    site_config: Object.keys(sys_app_cnf.site_config).includes(partner_id)
      ? sys_app_cnf.site_config[partner_id]
      : sys_app_cnf.site_config["default"],
    partner_id: partner_id,
    isLocalhost: req_hostname === "localhost" ? 1 : 0,
    localhost_app_id: "localhost_6LyNI78ngl_Gh2MlDWvZy",
  };
  sys_config_data[
    "aptGptAboutMe"
  ] = `I am ${sys_config_data.site_config.name} Next Gen Transformer AI Model, and I was developed by ${sys_config_data.site_config.name} Multiple Next Gen Transformer proprietary AI technology. I am an advanced language AI Model with over 154 billion parameters. My purpose is to assist and provide helpful responses to your queries. While I am not a human, I have been designed to understand and respond to natural language, just like a person would. Is there anything specific you would like to know or ask me?`;
  if (partner_id == "gulfgpt.io" || partner_id == "gulfgpt.bharatgpt.io") {
    sys_config_data[
      "aptGptAboutMe"
    ] += `\n\nأنا من الجيل التالي من طراز Transformer AI ، وقد تم تطويري بواسطة تقنية AI الخاصة بمحول الجيل التالي المتعدد. أنا نموذج متقدم للغة AI مع أكثر من 100 مليار معلمة. هدفي هو المساعدة وتقديم ردود مفيدة على استفساراتك. على الرغم من أنني لست إنسانًا ، فقد تم تصميمي لفهم اللغة الطبيعية والاستجابة لها ، تمامًا كما يفعل أي شخص. هل هناك أي شيء محدد تود أن تعرفه أو تسألني عنه؟`;
  }
  return sys_config_data;
}

export default sys_config;

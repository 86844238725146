import sys_cnf from '../../system/config/config.js';
import sys_app_cnf from '../../system/config/app-config.js';
import forgotPasswordInitAPI from '../../api/auth/forgotPasswordInit.js';
import forgotPasswordAPI from '../../api/auth/forgotPassword.js';
import getUser from '../../api/auth/getUser.js';
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import getCurrentLine from 'get-current-line';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { v4 as uuid } from 'uuid'
import 'react-phone-number-input/style.css'
import sysColor from "../../system/config/color.js";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

const ForgotPassword = () => {
    useEffect(() => {
        document.title = sys_config.site_config.name;
    }, []);

    const sys_config = sys_cnf();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [otpScreen, setOtpScreen] = useState(0);
    const [otpSentOnEmail, setOtpSentOnEmail] = useState(0);
    const [emailHidden, setEmailHidden] = useState("");
    const [otp, setOtp] = useState("");
    const [login_uid, setloginUid] = useState("");

    const forgotPassword = async () => {
        const toastId = uuid();
        toast.loading("Please wait...", {toastId: toastId});

        try {
            var forgotPasswordInit = await forgotPasswordInitAPI(email);
            if(forgotPasswordInit.status == 1){
                setOtpSentOnEmail(1);
                setEmailHidden(forgotPasswordInit.data.otp_sent_on.email);
                setloginUid(forgotPasswordInit.data.uid);
                setOtpScreen(1);
                toast.update(toastId, { render: forgotPasswordInit.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }else{
                toast.update(toastId, { render: forgotPasswordInit.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }
        } catch (error) {
            toast.update(toastId, { render: "Oops! Something went wrong", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
        }
    }

    const accountPasswordReset = async () => {
        const toastId = uuid();
        toast.loading("Please wait...", {toastId: toastId});

        try {
            var forgotPassword = await forgotPasswordAPI(login_uid,otp,password,password);
            if(forgotPassword.status == 1){
                toast.update(toastId, { render: forgotPassword.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
                navigate(`${sys_app_cnf.dashboard_url}/chat`);
            }else{
                toast.update(toastId, { render: forgotPassword.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }

        } catch (error) {
            toast.update(toastId, { render: "Oops! Something went wrong", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
        }
    }

    const backToForgotPassword = async() => {
        setOtpScreen(0);
        setOtpSentOnEmail(0);
    }

    useEffect(() => {
        var GetUser = getUser();
        if(GetUser.status == 1){
            navigate(`${sys_app_cnf.dashboard_url}/chat`);
        }
    },[navigate]);

    return (
        <Fragment>
            <div className="page-wrapper" style={{backgroundColor: '#fff'}}>
                <div className="container-fluid">
                    {(otpScreen != 1) &&
                        <div className="authentication-main" style={{backgroundColor: '#fff'}}>
                            <div className="row">
                                <div className="col-sm-12 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            {sys_config.site_config.logo != null ?
                                            <div className="text-center">
                                                <img src={sys_config.site_config.logo} alt="" style={{maxWidth: '300px', width: 'auto', maxHeight:'50px'}} />
                                            </div>:
                                            <></>
                                            }
                                            <div className="card mt-4 p-4">
                                                <h4 className="text-center">Forgot Password</h4>
                                                <h6 className="text-center">{"Enter your Email to rest account password"}</h6>
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Email</label>
                                                        <input className="form-control" type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required="" />
                                                    </div>
                                                    <div className="p-0" style={{"marginTop":"0px", marginBottom: '15px'}}>
                                                        <Link to="/login" style={{color: sysColor.primary}}><span className='text-muted'>Go back to </span>Login</Link>
                                                    </div>
                                                    <div className="form-group form-row mb-0 d-grid" style={{"marginTop":"5px"}}>
                                                        <button className="btn" style={{backgroundColor: sysColor.primary, color: '#fff'}} type="button" onClick={() => forgotPassword()}>Sent OTP</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {(otpScreen == 1) &&
                    <div className="authentication-main" style={{backgroundColor: '#fff'}}>
                            <div className="row">
                                <div className="col-sm-12 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            {sys_config.site_config.logo != null ?
                                            <div className="text-center">
                                                <img src={sys_config.site_config.logo} alt="" style={{maxWidth: '300px', width: 'auto', maxHeight:'50px'}} />
                                            </div>:
                                            <></>
                                            }
                                            <div className="card mt-4 p-4">
                                                <h4 className="text-center">OTP</h4>
                                                <h6 className="text-center">{"Enter your Email and Password"}</h6>
                                                <form className="theme-form">
                                                    
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">OTP</label>
                                                        <input className="form-control" type="number" value={otp} onChange={e => setOtp(e.target.value)} placeholder="OTP" />
                                                        <div className="col-form-label pt-0" style={{"fontSize":"10px"}}>OTP sent on {emailHidden}</div>
                                                    </div>
                                                    <div className="form-group" style={{"marginTop":"-25px"}}>
                                                        <label className="col-form-label">Password</label>
                                                        <input className="form-control focus" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" required="" />
                                                    </div>

                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn" style={{backgroundColor: sysColor.primary, color: '#fff'}} type="button" onClick={() => accountPasswordReset()}>Set Password</button>
                                                    </div>
                                                    <div className="p-0" style={{"marginTop":"15px"}}>
                                                        <button className="btn d-inline-block p-0" type="button" onClick={() => backToForgotPassword()}>
                                                            <div className='' style={{color: sysColor.primary}}>Back to forgot password?</div>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <ToastContainer newestOnTop pauseOnFocusLoss={false} />
                </div>
            </div>
        </Fragment>
    );
};

export default ForgotPassword;
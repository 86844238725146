import sys_cnf from '../../system/config/config.js';
import logOutUser from '../../api/auth/logOutUser.js';
import getCurrentLine from 'get-current-line';
import userImage from '../../assets/images/system/user.png';

var getUser = function() {
    const sys_config = sys_cnf();
    var sys_error = 0;
    var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
    try {
        var token, uid, user;

        if(sys_error == 0){
            try {
                token = localStorage.getItem('lid');
                uid = localStorage.getItem('uid');
                user = localStorage.getItem('user');
                var userDetail = {};
                if(user != null && user.length > 0 && user != 'null' && user != 'undefined'){
                    userDetail = JSON.parse(user);
                    if(userDetail.profile_photo == null){
                        userDetail.profile_photo = `${sys_config.req_domain}${userImage}`;
                    }
                }else{
                    userDetail = {
                        fname:'User',
                        lname:'Name'
                    };
                }

                if(token != null && token.length > 0 && token != 'null'&& token != 'undefined'){
                    // continue
                }else{
                    token = null;
                }

                if((token != null && token.length > 0) && (uid != null && uid.length > 0) && (user != null && user.length > 0)){
                    sys_respose = {'status':1,'message':'User detail','data':{'lid':token,'uid':uid,'user':userDetail},'code':200};
                }else{
                    sys_error = 1;
                    sys_respose = {'status':0,'message':'Unauthorized User','data':{},'code':400,'ev':getCurrentLine().line};
                }
            } catch (error) {
                sys_error = 1;
                sys_respose = {'status':0,'message':'Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
            }
        }

    }catch (error) {
        sys_error = 1;
        sys_respose = {'status':0,'message':'Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
    }

    if(sys_error == 1){
        logOutUser();
    }

    return sys_respose;
};

export default getUser;


import sys_cnf from '../../../system/config/config.js';
import sys_app_cnf from '../../../system/config/app-config.js';
import React, { useState, useEffect, Fragment } from 'react';
import logo from '../../../assets/images/store/logo-tbg.svg';
import getCurrentLine from 'get-current-line';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { v4 as uuid } from 'uuid'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import Select from 'react-select';
import {State } from 'country-state-city'
import Files from 'react-files'
import { Edit, XCircle, CheckCircle } from 'react-feather';
import { Container } from "reactstrap";
import getUser from '../../../api/auth/getUser.js';
import FormData from 'form-data'
import slugify from 'slugify';
import BarLoader from "react-spinners/BarLoader";
import sysColor from '../../../system/config/color.js';

const StoreUpdate = () => {
    var GetUser = getUser();
    const params = new URLSearchParams(window.location.search);
    const [name, setName] = useState("");
    const [storeCode, setStoreCode] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneCountry, setPhoneCountry] = useState("");
    const [email, setEmail] = useState("");
    const [buildingAddress, setBuildingAddress] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [description, setDescription] = useState("");
    const [selectedCountryOption, setSelectedCountryOption] = useState(null);
    const [selectedStateOption, setSelectedStateOption] = useState(null);
    const [stateSelectOption, setStateSelectOption] = useState(null);
    const [checkStoreCodeAvailabilityResponse, setCheckStoreCodeAvailabilityResponse] = useState(null);
    const [getStore, setGetStore] = useState({'status':-1,'data':'Loading','code':400});
    useEffect(async () => {
        if(params.get('id') != null && params.get('id').length > 0){
            const sys_config = sys_cnf();
            setGetStore({'status':-1,'message':'Loading','data':{},'code':400});
            var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
            var sys_error = sys_respose.status == 0?0:0;
            if(sys_error == 0){
                var dataResponse;
                await axios.get(`${sys_config.api_req_domain}/api/admin/store/store/get-my-store?store_id=${params.get('id')}`, {
                    headers: {
                        'Authorization': `Bearer ${GetUser.data.lid}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(function (response) {
                    dataResponse = response.data;
                })
                .catch(function (error) {
                    sys_error = 1;
                    sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':error.response.data.code,'ev':getCurrentLine().line}
                    setCheckStoreCodeAvailabilityResponse(sys_respose);
                });
            }
            
            if(sys_error == 0){
                sys_respose = {'status':dataResponse.status,'message':dataResponse.message,'data':dataResponse.data[0],'code':dataResponse.code};
                var tmpPhoneCodeCountry;
                for (const [key, value] of Object.entries(sys_app_cnf.countries_numeric_code)) {
                    if(value == sys_respose.data.phone_code){
                        tmpPhoneCodeCountry = key;
                        break;
                    }
                }
                sys_respose.data.phone_code_country = tmpPhoneCodeCountry;

                setGetStore(sys_respose);
                setName(sys_respose.data.name)
                setStoreCode(sys_respose.data.store_code)
                setPhoneCountry(sys_respose.data.phone_code_country)
                setEmail(sys_respose.data.email)
                setBuildingAddress(sys_respose.data.building_address)
                setStreetAddress(sys_respose.data.street_address)
                setCity(sys_respose.data.store_code)
                setPostalCode(sys_respose.data.zip_code)
                setDescription(sys_respose.data.description)
                setSelectedCountryOption({value:sys_respose.data.country,label:sys_app_cnf.countries_iso_name[sys_respose.data.country]})
            }
        }else{
            setGetStore({'status':0,'data':'Invalid store','code':400})
        }
    }, []);

    const [logoImage, setLogoImage] = useState([]);
    
    const onLogoChange = (files) => {
        setLogoImage(files)
    }
    const onLogoError = (error, file) => {
        setLogoImage(file)
    }
    
    var countrySelectOption = [];
    for (const [key, value] of Object.entries(sys_app_cnf.countries_iso_name)) {
        countrySelectOption.push({value: key, label: value})
    }

    useEffect(() => {
        document.title = 'Update Store - '+sys_app_cnf.site_name;
    }, []);

    const checkStoreCodeAvailability = async () => {
        if(storeCode != null && storeCode.length > 0){
            const sys_config = sys_cnf();
            setCheckStoreCodeAvailabilityResponse({'status':-1,'message':'','data':{},'code':400});
            var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
            var sys_error = sys_respose.status == 0?0:0;
            if(sys_error == 0){
                var dataResponse;
                await axios.get(`${sys_config.api_req_domain}/api/admin/store/store/check-store-code-availability?store_code=${storeCode}&store_id=${params.get('id')}`, {
                    headers: {
                        'Authorization': `Bearer ${GetUser.data.lid}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(function (response) {
                    dataResponse = response.data;
                })
                .catch(function (error) {
                    sys_error = 1;
                    sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':error.response.data.code,'ev':getCurrentLine().line}
                    setCheckStoreCodeAvailabilityResponse(sys_respose);
                });
            }
            
            if(sys_error == 0){
                sys_respose = {'status':dataResponse.status,'message':dataResponse.message,'data':{},'code':dataResponse.code};
                setCheckStoreCodeAvailabilityResponse(sys_respose);
            }

            if(storeCode == null || storeCode.length == 0){
                setCheckStoreCodeAvailabilityResponse(null);
            }
        }
    }

    useEffect(() => {
        checkStoreCodeAvailability();
    }, [storeCode]);

    useEffect(async() => {
        var delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(3);
        
        if(phoneCountry != null && phoneCountry.length > 0){
            setPhone('+'+getStore.data.phone_code+' '+getStore.data.phone)
        }
    }, [phoneCountry]);

    const editStore = async () => {
        const sys_config = sys_cnf();
        var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
        var sys_error = sys_respose.status == 0?0:0;
        const toastId = uuid();
        toast.loading("Store updating...", {toastId: toastId});

        try {
            var phoneNumber;
            if(phone != null){
                phoneNumber = parsePhoneNumber(phone);
            }

            if(sys_error == 0){
                let sendDta = new FormData();
                sendDta.append('store_id', getStore.data.uid);
                sendDta.append('name', name);
                sendDta.append('store_code', storeCode);
                sendDta.append('email', email);
                sendDta.append('phone', phoneNumber != null?phoneNumber.nationalNumber:'');
                sendDta.append('phone_country', phoneNumber != null?phoneNumber.country:'');
                sendDta.append('description', description);
                sendDta.append('country', selectedCountryOption.value);
                sendDta.append('state', selectedStateOption.value);
                sendDta.append('city', city);
                sendDta.append('street_address', streetAddress);
                sendDta.append('building_address', buildingAddress);
                sendDta.append('zip_code', postalCode);
                if(logoImage.length > 0){
                    sendDta.append('logo_image', logoImage[0]);
                }
                var dataResponse;
                await axios.post(`${sys_config.api_req_domain}/api/admin/store/store/edit`, sendDta,{
                    headers: {
                        'Authorization': `Bearer ${GetUser.data.lid}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                  })
                  .then(function (response) {
                    dataResponse = response.data;
                  })
                  .catch(function (error) {
                    sys_error = 1;
                    sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':400,'ev':getCurrentLine().line}
                  });
            }
            
            if(sys_error == 0){
                if(dataResponse.status == 1){
                    localStorage.setItem("store_id",dataResponse.data.store_id);
                    localStorage.setItem("store",JSON.stringify(dataResponse.data.store));
                    localStorage.setItem("my_store_permission",JSON.stringify(dataResponse.data.my_store_permission));
                    sys_respose = {'status':1,'message':dataResponse.message,'data':{},'code':200};
                }else{
                    sys_error = 1;
                    sys_respose = {'status':0,'message':'Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
                }
            }

            if(sys_error == 0){
                toast.update(toastId, { render: sys_respose.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }else{
                toast.update(toastId, { render: sys_respose.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }

        } catch (error) {
            toast.update(toastId, { render: "Oops! Something went wrong", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
        }
    }

    var stateList = [];
    useEffect(() => {
        if(selectedCountryOption != null){
            stateList = State.getStatesOfCountry(selectedCountryOption.value);
        }
    }, [selectedCountryOption]);
    
    useEffect(async () => {
        if(stateList.length > 0){
            var tmpStateSelectOption = [];
            for (const [key, value] of Object.entries(stateList)) {
                tmpStateSelectOption.push({value: value.isoCode, label: value.name})
            }
            if(tmpStateSelectOption.length > 0){
                setStateSelectOption(tmpStateSelectOption);
                setSelectedStateOption(null);
            }
        }
    }, [stateList]);

    useEffect(() => {
        if(stateSelectOption != null && stateSelectOption.length > 0){
            var tmpSelectState;
            for (const [key, value] of Object.entries(stateSelectOption)) {
                if(value.value == getStore.data.state){
                    tmpSelectState = value;
                    break;
                }
            }

            if(selectedStateOption == null){
                if(tmpSelectState != null){
                    setSelectedStateOption(tmpSelectState);
                }else{
                    setSelectedStateOption(stateSelectOption[0]);
                }
            }
        }
    }, [stateSelectOption]);

    return (
        <Fragment>
            {/* <Breadcrumb title="New Store" parent="Store" /> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 p-0">
                        <div className="card mt-4 p-4">
                            <h4 className="text-left">Update Store</h4>
                            {getStore.status == 1 &&
                                <div className="theme-form" style={{"marginTop":"30px"}}>
                                    <Container fluid={true} className="p-0 mb-2">
                                        <div>
                                            <Files
                                                className='files-dropzone fileContainer'
                                                onChange={onLogoChange}
                                                onError={onLogoError}
                                                accepts={['image/*']}
                                                multiple={false}
                                                maxFileSize={10000000}
                                                minFileSize={0}
                                                clickable
                                            >

                                                {
                                                    logoImage.length > 0
                                                        ?   <>
                                                                <div className='files-gallery d-inline-block'>
                                                                    {logoImage.map((file,index) =>
                                                                        <img className='files-gallery-item lazyloaded blur-up' style={{width:'180px', maxHeight:'70px', borderRadius:'10px', objectFit:'fill'}} alt="img" src={file.preview.url} key={index} />
                                                                    )}
                                                                </div>
                                                                <div className="profile-edit">
                                                                    <Edit style={{width:'14px'}} />
                                                                </div>
                                                            </>
                                                        : <>
                                                            <div className='files-gallery d-inline-block'>
                                                                <img className='files-gallery-item lazyloaded blur-up' style={{width:'180px', maxHeight:'70px', borderRadius:'10px', objectFit:'fill'}} alt="img" src={getStore.data.logo_image} />
                                                                <div className="profile-edit">
                                                                    <Edit style={{width:'14px'}} />
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </Files>
                                        </div>
                                    </Container>
                                    <div className="row">
                                        <div className="form-group col-sm-12 col-md-6 pr-3">
                                            <label className="col-form-label">Store Name*</label>
                                            <input className="form-control" type="text" placeholder="Store Name" value={name} onChange={e => setName(e.target.value)} />
                                        </div>
                                        <div className="form-group col-sm-12 col-md-6 pl-3">
                                            <label className="col-form-label">{"Store Code*"}</label>
                                            <input className="form-control" type="text" placeholder="Store Code" value={storeCode} onChange={e => {setStoreCode(e.target.value); }} />
                                            {(checkStoreCodeAvailabilityResponse != null && checkStoreCodeAvailabilityResponse.status == 1) &&
                                                <div className='d-flex'>
                                                    <div className='p-0'><CheckCircle className='text-success' style={{'width':'14px'}} /></div>
                                                    <div className="col-form-label text-success p-0" style={{'margin':'2px 3px'}}>{checkStoreCodeAvailabilityResponse.message}</div>
                                                </div>
                                            }
                                            {(checkStoreCodeAvailabilityResponse != null && checkStoreCodeAvailabilityResponse.code == 404)  &&
                                                <div className='d-flex'>
                                                    <div className='p-0'><XCircle className='text-danger' style={{'width':'14px'}} /></div>
                                                    <div className="col-form-label text-danger p-0" style={{'margin':'2px 3px'}}>{checkStoreCodeAvailabilityResponse.message}</div>
                                                </div>
                                            }
                                            {(checkStoreCodeAvailabilityResponse != null && checkStoreCodeAvailabilityResponse.status == -1)  &&
                                                <span className="col-form-label text-info">{"Checking store code availability..."}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-sm-12 col-md-6 pr-3">
                                            <label className="col-form-label pt-0">Mobile*</label>
                                            <PhoneInput defaultCountry={phoneCountry} placeholder="Mobile" value={phone} onChange={setPhone} error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
                                        </div>
                                        <div className="form-group col-sm-12 col-md-6 pr-3">
                                            <label className="col-form-label">{"Email (Optional)"}</label>
                                            <input className="form-control" type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                    
                                    {/* <div className="form-group">
                                        <label className="col-form-label">{"Accept Order In Currency"}</label>
                                        <Select Select mode="multiple" defaultValue={['INR']} style={{ width: "100%" }}>
                                            {Object.keys(sys_app_cnf.currency_code_to_symbol).map((currency,i) =>
                                                <Select.Option value={currency} key={i} selected>{`${currency} (${sys_app_cnf.currency_code_to_symbol[currency]})`} </Select.Option>
                                            )}
                                        </Select>
                                    </div> */}

                                    <label className="col-form-label">{"Store Address"}</label>
                                    <div className="row">
                                        <div className="form-group col-sm-12 col-md-6 pr-3">
                                            <Select
                                                value={selectedCountryOption}
                                                onChange={setSelectedCountryOption}
                                                options={countrySelectOption}
                                            />
                                        </div>
                                        <div className="form-group col-sm-12 col-md-6 pr-3">
                                            <Select
                                                value={selectedStateOption}
                                                onChange={setSelectedStateOption}
                                                options={stateSelectOption}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="form-group col-sm-12 col-md-6 pr-3">
                                            <label className="col-form-label">{"Building Address"}</label>
                                            <input className="form-control" type="text" placeholder="Building Address" value={buildingAddress} onChange={e => setBuildingAddress(e.target.value)} />
                                        </div>
                                        
                                        <div className="form-group col-sm-12 col-md-6 pr-3">
                                            <label className="col-form-label">{"Street Address"}</label>
                                            <input className="form-control" type="text" placeholder="Street Address" value={streetAddress} onChange={e => setStreetAddress(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-sm-12 col-md-6 pr-3">
                                            <label className="col-form-label">{"City/Town"}</label>
                                            <input className="form-control" type="text" placeholder="City/Town" value={city} onChange={e => {setCity(e.target.value); }} />
                                        </div>
                                        <div className="form-group col-sm-12 col-md-6 pr-3">
                                            <label className="col-form-label">{"Postal/Zip Code"}</label>
                                            <input className="form-control" type="text" placeholder="Postal/Zip Code" value={postalCode} onChange={e => {setPostalCode(e.target.value); }} />
                                        </div>
                                    </div>

                                    <div className="form-group col-sm-12 pr-3">
                                        <label className="col-form-label">{"Description (Seo)"}</label>
                                        <textarea rows={3} maxLength={150} className="form-control" placeholder="Description" value={description} onChange={e => {setDescription(e.target.value);}} />
                                    </div>
                                    
                                    <div className="form-group form-row mt-3 mb-0 d-grid w-150">
                                        <button className="btn btn-primary" type="button" onClick={() => editStore()}>Update Store</button>
                                    </div>
                                </div>
                            }
                            
                            {getStore.status == 0 &&
                                <div>
                                    {getStore.data}
                                </div>
                            }

                            {getStore.status == -1 &&
                                <div className="col-sm-12 pr-3">
                                    <BarLoader
                                        color={sysColor.primary}
                                        loading={true}
                                        width={'100%'}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default StoreUpdate;
import getCurrentLine from 'get-current-line';
let logOutUser = function() {
    var sys_error = 0;
    var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
    try {
        if(sys_error == 0){
            localStorage.removeItem("lid");
            localStorage.removeItem("uid");
            localStorage.removeItem("user");
            localStorage.removeItem("serverSetting");
    
            sys_respose = {'status':1,'message':'You are logout','data':{},'code':200};
        }
    }catch (error) {
        sys_error = 1;
        sys_respose = {'status':0,'message':'Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
    }

    return sys_respose;
};

export default logOutUser;


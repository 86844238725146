import sys_cnf from '../../system/config/config.js';
import sys_app_cnf from '../../system/config/app-config.js';
import loginAPI from '../../api/auth/login.js';
import getUser from '../../api/auth/getUser.js';
import React, {useState, useEffect} from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuid } from 'uuid'
import 'react-phone-number-input/style.css'
import sysColor from "../../system/config/color.js";

const Logins = () => {
    useEffect(() => {
        document.title = sys_config.site_config.name;
    }, []);

    const sys_config = sys_cnf();
    const [login_id_type, setLoginIdType] = useState("email");
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [loginChecked, setLoginChecked] = useState(0);
    const [inputs, setInputs] = useState({
        "email":"",
        "phone":"",
        "password":""
    });

    const handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
    
        setInputs({
          ...inputs,
          [name]: value,
        });
    };
    
    const loginAuth = async () => {
        const toastId = uuid();
        toast.loading("Please wait...", {toastId: toastId});

        try {
            var login = await loginAPI(inputs.email, inputs.password);
            if(login.status == 1){
                toast.update(toastId, { render: login.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
                navigate(`${sys_app_cnf.dashboard_url}/chat`);
            }else{
                toast.update(toastId, { render: login.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }

        } catch (error) {
            toast.update(toastId, { render: "Oops! Something went wrong", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
        }
    }
    
    useEffect(() => {
        var GetUser = getUser();
        if(GetUser.status == 1){
            navigate(`${sys_app_cnf.dashboard_url}/chat`);
        }
    },[navigate]);

    if(loginChecked == 0){
        setLoginChecked(1);
    }
    
    return (
        <div>
            <div className="page-wrapper" style={{backgroundColor: '#fff'}}>
                <div className="container-fluid p-0">
                    <div className="authentication-main" style={{backgroundColor: '#fff'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        {sys_config.site_config.logo != null ?
                                        <div className="text-center">
                                            <img src={sys_config.site_config.logo} alt="" style={{maxWidth: '300px', width: 'auto', maxHeight:'50px'}} />
                                        </div>:
                                        <></>
                                        }
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>LOGIN</h4>
                                                    <h6>{"Enter your Email and Password"} </h6>
                                                </div>
                                                
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Email</label>
                                                        <input className="form-control" name="email" type="email" value={inputs.email} onChange={handleInputChange} placeholder="Email" required="" />
                                                    </div>
                                                    <div className="form-group" style={{"marginTop":"0px"}}>
                                                        <label className="col-form-label">Password</label>
                                                        <input className="form-control focus" name="password" type="password" value={inputs.password} onChange={handleInputChange} placeholder="Password" required="" />
                                                    </div>
                                                    <div className="p-0 row">
                                                        <div className='col-md-6'>
                                                            <Link to="/forgot-password" style={{color: sysColor.primary}}>Forgot password?</Link>
                                                        </div>
                                                        <div className='col-md-6' style={{"textAlign":"right"}}>
                                                            <Link to="/signup" style={{color: sysColor.primary}}>Create new account</Link>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn" style={{backgroundColor: sysColor.primary, color: '#fff'}} type="button" onClick={() => loginAuth()}>Login</button>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer newestOnTop pauseOnFocusLoss={false} />
                </div>
            </div>
        </div>
    );
};

export default Logins;
import React from 'react';
import { useEffect } from 'react';
import { Navigate, useNavigate, Route, Routes } from 'react-router-dom';
import {
  configureFakeBackend
} from '../services/fack.backend';
import configDB from '../data/customizer/config'
import PrivateRoute from './private-route';
import LanguageModelAptGPT from '../pages/service/languageModel/aptGPT/aptGPT.js';
import LanguageModelBharatGPT from '../pages/service/languageModel/aptGPT/bharatGPT.js';
import SearchLLMv2 from '../pages/service/languageModel/aptGPT/searchLLMv2.js';
import MapLLM from '../pages/service/languageModel/aptGPT/mapGPT.js';
import NewsLLM from '../pages/service/languageModel/aptGPT/newsLLM.js';
import NewsLLM3 from '../pages/service/languageModel/aptGPT/newsLLMv3.js';
import DeepResearch from '../pages/service/languageModel/aptGPT/deepResearch.js';
import ShopLLM from '../pages/service/languageModel/aptGPT/shopLLM.js';
import Text2imgV1 from '../pages/service/text2img/v1.js';
import Img2TextV1 from '../pages/service/img2text/v1.js';
import PdfQaV1 from '../pages/service/doc/pdfQa.js';
import AppLayout from '../components/app';
import Login from '../pages/auth/login';
import ForgotPassword from '../pages/auth/forgot-password';
import Signup from '../pages/auth/signup';
import ComingSoon from '../pages/comingsoon';
import Maintenance from '../pages/maintenance';
import Error400 from '../pages/errors/error400';
import Error401 from '../pages/errors/error401';
import Error403 from '../pages/errors/error403';
import Error404 from '../pages/errors/error404';
import Error500 from '../pages/errors/error500';
import Error503 from '../pages/errors/error503';
import { routes } from './layouts-routes';
import Callback from '../api/auth/callback';
import sys_cnf from "../system/config/config.js";
import Status from '../pages/tools/status.js';
// import Ecommerce from '../components/dashboard/ecommerce';
// import LoginWithVideo from '../pages/loginWithVideo';
// import SignupWithImg from '../pages/signupWithImg';
// import SignupWithVideo from '../pages/signupWithVideo';
// import UnlockUser from '../pages/unlockUser';
// import ForgetPwd from '../pages/forgetPwd';
// import ResetPwd from '../pages/resetPwd';
// import ComingSoonImg from '../pages/comingsoonImg';
// import ComingSoonVideo from '../pages/comingsoonVideo';
// import getUserByToken from '../api/auth/getUserByToken.js';
// import logOutUser from '../api/auth/logOutUser.js';

configureFakeBackend();

const MainRoutes = () => {
  const navigate = useNavigate();
  const sys_config = sys_cnf();
  const jwt_token = localStorage.getItem('lid');
  const uid = localStorage.getItem('uid');
  const user = localStorage.getItem('user');

  useEffect(() => {
    const abortController = new AbortController();
    // if(uid != null && user != null && jwt_token != null){
    //   getUserByToken(jwt_token).then((response)=> {
    //     if(response.status != 1){
    //         logOutUser();
    //         navigate(`${process.env.PUBLIC_URL}/login`);
    //     }
    //   });
    // }else{
    //   logOutUser();
    //   navigate(`${process.env.PUBLIC_URL}/login`);
    // }
    const color = localStorage.getItem('color')
    const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
    document.body.classList.add(layout);
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

    return function cleanup() {
      abortController.abort();
    }
  }, [jwt_token, uid, user, navigate]);

  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />} >
        <Route path="/:id" element={sys_config.partner_id == 'bharatgpt.io'? <LanguageModelBharatGPT />: <LanguageModelAptGPT />} />
        <Route path="/chat/:id" element={sys_config.partner_id == 'bharatgpt.io'? <LanguageModelBharatGPT />:<LanguageModelAptGPT />} />
        <Route path="/text2img/:id" element={<Text2imgV1 />} />
        <Route path="/img2text/:id" element={<Img2TextV1 />} />
        <Route path="/search/:id" element={<SearchLLMv2 />} />
        <Route path="/doc/pdf/qa/:id" element={<PdfQaV1 />} />
        <Route path="/place/:id" element={<MapLLM />} />
        <Route path="/research/:id" element={<DeepResearch />} />
        <Route path="/news/:id" element={<NewsLLM />} />
        <Route path="/shop/:id" element={<ShopLLM />} />
        <Route path="/news-detail/:id" element={<NewsLLM3 />} />
        {/* <Route path="/dashboard" element={<LanguageModelAptGPT />} /> */}
          {/* {routes.map(({ path, Component }, i) => (
            <Route element={<AppLayout />} key={i}>
              {jwt_token && <Route exact
                path={`/`}
                element={<LanguageModelAptGPT/>}
              />}
              <Route exact path={path} element={Component} />
            </Route>
          ))} */}
        </Route>
        {/* <Route exact path={`${process.env.PUBLIC_URL}/login1`} element={<Signin />} /> */}
        <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        <Route path={`${process.env.PUBLIC_URL}/status`} element={<Status />} />
        <Route path={`${process.env.PUBLIC_URL}/forgot-password`} element={<ForgotPassword />} />
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/loginWithVideo`} element={<LoginWithVideo />} /> */}
        <Route path={`${process.env.PUBLIC_URL}/signup`} element={<Signup />} />
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/signupWithImg`} element={<SignupWithImg />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/signupWithVideo`} element={<SignupWithVideo />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/unlockUser`} element={<UnlockUser />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} element={<ForgetPwd />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/resetPwd`} element={<ResetPwd />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} element={<ComingSoon />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonImg`} element={<ComingSoonImg />} />
        <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonVideo`} element={<ComingSoonVideo />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} element={<Maintenance />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} element={<Error400 />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} element={<Error401 />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} element={<Error403 />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} element={<Error404 />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} element={<Error500 />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} element={<Error503 />} /> */}
        {/* <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} /> */}
        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );


};

export default MainRoutes;
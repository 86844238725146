import React, { Fragment, useEffect, useRef } from 'react';
import sys_cnf from "../../system/config/config.js";
const Status = () => {
    const sys_config = sys_cnf();
    useEffect(() => {
        document.title = sys_config.site_config.name;
    }, []);

    return (
        <Fragment>
            <iframe
                title={sys_config.site_config.name}
                id="my-iframe"
                src="https://p2pserver.statuspage.io/"
                frameBorder="0"
                style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%' }}
            />
        </Fragment>
    )
}
export default Status
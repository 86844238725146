import sys_cnf from '../../../system/config/config.js';
import getUserAPI from '../../auth/getUser.js';
import getUserSetting from '../../app/setting/getUserSetting.js';
import getCurrentLine from 'get-current-line';
import axios from 'axios';

let mapLLM = async function(query) {
    const sys_config = sys_cnf();
    var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
    var sys_error = 0;
    
    try {
        var token;
        var model = "llmv2";

        var GetUserSetting = getUserSetting();
        if (GetUserSetting.hasOwnProperty('language_model') && GetUserSetting['language_model']['setting'] != null && GetUserSetting['language_model']['setting'].length > 0) {
            model = GetUserSetting['language_model']['setting']
        }

        var GetUser = getUserAPI();
        if(GetUser.status != 1){
            sys_error = 1;
            sys_respose = {'status':0,'message':'Unauthorized User','data':{},'code':400,'ev':getCurrentLine().line};
        }else{
            token = GetUser.data.lid;
        }
        
        if(sys_error == 0){
            var dataResponse;
            await axios.post(`${sys_config.api_req_domain}/api/service/llm/map`, {
                'q':query,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
              })
              .then(function (response) {
                dataResponse = response.data;
              })
              .catch(function (error) {
                sys_error = 1;
                sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':400,'ev':getCurrentLine().line}
              });
        }
        
        if(sys_error == 0){
            sys_respose = {'status':dataResponse.status,'message':dataResponse.message,'data':dataResponse.data,'code':dataResponse.code};
        }

    } catch (error) {
        sys_error = 1;
        sys_respose = {'status':0,'message':'Oops! Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
    }

    return sys_respose;
};

export default mapLLM;
import logo from "../../assets/image/logo/logo.svg";
import chatLogo from "../../assets/image/page/chat.svg";
import chatLogoNextLabs from "../../assets/image/page/chat-2.svg";
import bharatGPTchat from "../../assets/image/page/bharatGPT-chat.png";
import bharatGPTLogo from "../../assets/image/page/bharatGPT-logo.svg";
import gulfGPTchat from "../../assets/image/page/GulfGPTchatIcon.png";
import gulfGPTlogo from "../../assets/image/page/GulfGPTlogo.png";
import booteAIlogo from "../../assets/image/page/booteAI-logo.png";
import booteAIlogoSvg from "../../assets/image/page/booteAI-logo.svg";
import MetaDeeAIlogo from "../../assets/image/page/MetaDee-logo.png";
import MetaDeeAISvg from "../../assets/image/page/metaDee-logo.svg";
var sys_app_config = {
  dashboard_url: `${process.env.PUBLIC_URL}`,
  image_file_max_size_mb: 10,
  site_config: {
    "openlabai.com": {
      name: "QuantumX",
      logo: null,
      chatLogo: chatLogo,
    },
    "nextlabai.com": {
      name: "NextLab GPT",
      logo: logo,
      chatLogo: chatLogoNextLabs,
    },
    "mangal.ai": {
      name: "Mangal GPT",
      logo: null,
      chatLogo: chatLogo,
    },
    "bharatgpt.io": {
      name: "Bharat GPT",
      logo: bharatGPTLogo,
      chatLogo: bharatGPTchat,
    },
    "gulfgpt.bharatgpt.io": {
      name: "Gulf GPT",
      logo: gulfGPTlogo,
      chatLogo: gulfGPTchat,
    },
    "gulfgpt.io": {
      name: "Gulf GPT",
      logo: gulfGPTlogo,
      chatLogo: gulfGPTchat,
    },
    "boote.ai": {
      name: "Boote AI",
      logo: booteAIlogo,
      chatLogo: chatLogoNextLabs,
    },
    "default.ai": {
      name: "NextLab GPT",
      logo: logo,
      chatLogo: chatLogo,
    },
    "metadee.io": {
      name: "MetaDee GPT",
      logo: MetaDeeAIlogo,
      chatLogo: MetaDeeAISvg,
    },
    default: {
      name: "Bharat GPT",
      logo: bharatGPTLogo,
      chatLogo: bharatGPTchat,
    },
  },
};

const sys_app_cnf = sys_app_config;
export default sys_app_cnf;

import sys_cnf from '../../../system/config/config.js';
import sys_app_cnf from '../../../system/config/app-config.js';
import React, { useState, useEffect, Fragment } from 'react';
import logo from '../../../assets/images/store/logo-tbg.svg';
import { useNavigate} from 'react-router-dom';
import getCurrentLine from 'get-current-line';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { v4 as uuid } from 'uuid'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import Select from 'react-select';
import {State } from 'country-state-city'
import Files from 'react-files'
import { Edit, XCircle, CheckCircle } from 'react-feather';
import { Container } from "reactstrap";
import getUser from '../../../api/auth/getUser.js';
import FormData from 'form-data'
import slugify from 'slugify';

const StoreCreate = () => {
    var GetUser = getUser();
    const [name, setName] = useState("");
    const [storeCode, setStoreCode] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [buildingAddress, setBuildingAddress] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [description, setDescription] = useState("");
    const navigate = useNavigate();
    const [selectedCountryOption, setSelectedCountryOption] = useState(null);
    const [selectedStateOption, setSelectedStateOption] = useState(null);
    const [stateSelectOption, setStateSelectOption] = useState(null);
    const [ipDetail, setIpDetail] = useState(null);
    const [postalCodeManuallyChanged, setPostalCodeManuallyChanged] = useState(0);
    const [cityManuallyChanged, setCityManuallyChanged] = useState(0);
    const [checkStoreCodeAvailabilityResponse, setCheckStoreCodeAvailabilityResponse] = useState(null);
    const [storeCodeManuallyChanged, setStoreCodeManuallyChanged] = useState(0);

    const [logoImage, setLogoImage] = useState([]);
    
    const onLogoChange = (files) => {
        setLogoImage(files)
    }
    const onLogoError = (error, file) => {
        setLogoImage(file)
    }
    
    var countrySelectOption = [];
    for (const [key, value] of Object.entries(sys_app_cnf.countries_iso_name)) {
        countrySelectOption.push({value: key, label: value})
    }

    useEffect(() => {
        document.title = 'Create New Store - '+sys_app_cnf.site_name;
    }, []);

    useEffect(() => {
        axios.get('https://ipapi.co/json/').then(async (response) => {
            let data = response.data;
            setIpDetail(data);
            if(selectedCountryOption == null){
                setSelectedCountryOption({value:data.country_code,label:sys_app_cnf.countries_iso_name[data.country_code]})
            }
        }).catch((error) => {
            if(selectedCountryOption == null){
                setSelectedCountryOption({value:'IN',label:sys_app_cnf.countries_iso_name['IN']})
            }
            setIpDetail({'country_code':'IN','region_code':'RJ','city':'Jaipur'});
        });
    },[]);

    const checkStoreCodeAvailability = async () => {
        if(storeCode != null && storeCode.length > 0){
            const sys_config = sys_cnf();
            setCheckStoreCodeAvailabilityResponse({'status':-1,'message':'','data':{},'code':400});
            var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
            var sys_error = sys_respose.status == 0?0:0;
            if(sys_error == 0){
                var dataResponse;
                await axios.get(`${sys_config.api_req_domain}/api/admin/store/store/check-store-code-availability?store_code=${storeCode}`, {
                    headers: {
                        'Authorization': `Bearer ${GetUser.data.lid}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(function (response) {
                    dataResponse = response.data;
                })
                .catch(function (error) {
                    sys_error = 1;
                    sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':error.response.data.code,'ev':getCurrentLine().line}
                    setCheckStoreCodeAvailabilityResponse(sys_respose);
                });
            }
            
            if(sys_error == 0){
                sys_respose = {'status':dataResponse.status,'message':dataResponse.message,'data':{},'code':dataResponse.code};
                setCheckStoreCodeAvailabilityResponse(sys_respose);
            }

            if(storeCode == null || storeCode.length == 0){
                setCheckStoreCodeAvailabilityResponse(null);
            }
        }
    }

    useEffect(() => {
        checkStoreCodeAvailability();
    }, [storeCode]);

    useEffect(() => {
        if(storeCodeManuallyChanged == 0){
            var tmpStoreCode = slugify(name, {
                replacement: '-',  // replace spaces with replacement character, defaults to `-`
                remove: undefined, // remove characters that match regex, defaults to `undefined`
                lower: true,      // convert to lower case, defaults to `false`
                strict: true,     // strip special characters except replacement, defaults to `false`
                locale: 'en',       // language code of the locale to use
                trim: true         // trim leading and trailing replacement chars, defaults to `true`
              });
            setStoreCode(tmpStoreCode);
        }
    }, [name]);

    const createStore = async () => {
        const sys_config = sys_cnf();
        var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
        var sys_error = sys_respose.status == 0?0:0;
        const toastId = uuid();
        toast.loading("Store creating...", {toastId: toastId});

        try {
            var phoneNumber;
            if(phone != null){
                phoneNumber = parsePhoneNumber(phone);
            }

            if(sys_error == 0){
                let sendDta = new FormData();
                sendDta.append('name', name);
                sendDta.append('store_code', storeCode);
                sendDta.append('email', email);
                sendDta.append('phone', phoneNumber != null?phoneNumber.nationalNumber:'');
                sendDta.append('phone_country', phoneNumber != null?phoneNumber.country:'');
                sendDta.append('currency', ipDetail.currency != null?ipDetail.currency:'INR');
                sendDta.append('description', description);
                sendDta.append('country', selectedCountryOption.value);
                sendDta.append('state', selectedStateOption.value);
                sendDta.append('city', city);
                sendDta.append('street_address', streetAddress);
                sendDta.append('building_address', buildingAddress);
                sendDta.append('zip_code', postalCode);
                if(logoImage.length > 0){
                    sendDta.append('logo_image', logoImage[0]);
                }
                var dataResponse;
                await axios.post(`${sys_config.api_req_domain}/api/admin/store/store/create`, sendDta,{
                    headers: {
                        'Authorization': `Bearer ${GetUser.data.lid}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                  })
                  .then(function (response) {
                    dataResponse = response.data;
                  })
                  .catch(function (error) {
                    sys_error = 1;
                    sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':400,'ev':getCurrentLine().line}
                  });
            }
            
            if(sys_error == 0){
                if(dataResponse.status == 1){
                    localStorage.setItem("store_id",dataResponse.data.store_id);
                    localStorage.setItem("store",JSON.stringify(dataResponse.data.store));
                    localStorage.setItem("my_store_permission",JSON.stringify(dataResponse.data.my_store_permission));
                    sys_respose = {'status':1,'message':dataResponse.message,'data':{},'code':200};
                }else{
                    sys_error = 1;
                    sys_respose = {'status':0,'message':'Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
                }
            }

            if(sys_error == 0){
                toast.update(toastId, { render: sys_respose.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
                navigate(`${process.env.PUBLIC_URL}/store/list`);
            }else{
                toast.update(toastId, { render: sys_respose.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }

        } catch (error) {
            toast.update(toastId, { render: "Oops! Something went wrong", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
        }
    }

    var stateList = [];
    useEffect(() => {
        if(selectedCountryOption != null){
            stateList = State.getStatesOfCountry(selectedCountryOption.value);
        }
    }, [selectedCountryOption]);
    
    useEffect(async () => {
        if(stateList.length > 0){
            var tmpStateSelectOption = [];
            for (const [key, value] of Object.entries(stateList)) {
                tmpStateSelectOption.push({value: value.isoCode, label: value.name})
            }
            if(tmpStateSelectOption.length > 0){
                setStateSelectOption(tmpStateSelectOption);
                setSelectedStateOption(null);
            }
        }
    }, [stateList]);

    useEffect(async () => {
        if(selectedStateOption != null && selectedCountryOption != null){
            if(ipDetail != null && ipDetail.region_code != null && ipDetail.country_code != null && ipDetail.country_code == selectedCountryOption.value && ipDetail.region_code == selectedStateOption.value){
                if(postalCodeManuallyChanged == 0){
                    setPostalCode(ipDetail.postal);
                }

                if(cityManuallyChanged == 0){
                    setCity(ipDetail.city);
                }
            }else{
                if(postalCodeManuallyChanged == 0){
                    setPostalCode("");
                }

                if(cityManuallyChanged == 0){
                    setCity("");
                }
            }
        }
    }, [selectedStateOption]);

    useEffect(() => {
        if(stateSelectOption != null && stateSelectOption.length > 0){
            if(ipDetail != null && ipDetail.region_code != null && ipDetail.country_code != null && ipDetail.country_code == selectedCountryOption.value){
                var tmpSelectState;
                for (const [key, value] of Object.entries(stateSelectOption)) {
                    if(value.value == ipDetail.region_code){
                        tmpSelectState = value;
                        break;
                    }
                }

                if(selectedStateOption == null){
                    if(tmpSelectState != null){
                        setSelectedStateOption(tmpSelectState);
                    }else{
                        setSelectedStateOption(stateSelectOption[0]);
                    }
                }
            }else{
                if(selectedStateOption == null){
                    setSelectedStateOption(stateSelectOption[0]);
                }
            }
        }
    }, [stateSelectOption]);

    return (
        <Fragment>
            {/* <Breadcrumb title="New Store" parent="Store" /> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 p-0">
                        <div className="card mt-4 p-4">
                            <h4 className="text-left">Create New Store</h4>
                            <div className="theme-form" style={{"marginTop":"30px"}}>
                                <Container fluid={true} className="p-0 mb-2">
                                    <div>
                                        <Files
                                            className='files-dropzone fileContainer'
                                            onChange={onLogoChange}
                                            onError={onLogoError}
                                            accepts={['image/*']}
                                            multiple={false}
                                            maxFileSize={10000000}
                                            minFileSize={0}
                                            clickable
                                        >

                                            {
                                                logoImage.length > 0
                                                    ?   <>
                                                            <div className='files-gallery d-inline-block'>
                                                                {logoImage.map((file,index) =>
                                                                    <img className='files-gallery-item lazyloaded blur-up' style={{width:'180px', maxHeight:'70px', borderRadius:'10px', objectFit:'fill'}} alt="img" src={file.preview.url} key={index} />
                                                                )}
                                                            </div>
                                                            <div className="profile-edit">
                                                                <Edit style={{width:'14px'}} />
                                                            </div>
                                                        </>
                                                    : <>
                                                        <div className='files-gallery d-inline-block'>
                                                            <img className='files-gallery-item lazyloaded blur-up' style={{width:'180px', maxHeight:'70px', borderRadius:'10px', objectFit:'fill'}} alt="img" src={logo} />
                                                            <div className="profile-edit">
                                                                <Edit style={{width:'14px'}} />
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </Files>
                                    </div>
                                </Container>
                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-6 pr-3">
                                        <label className="col-form-label">Store Name*</label>
                                        <input className="form-control" type="text" placeholder="Store Name" value={name} onChange={e => setName(e.target.value)} />
                                    </div>
                                    <div className="form-group col-sm-12 col-md-6 pl-3">
                                        <label className="col-form-label">{"Store Code*"}</label>
                                        <input className="form-control" type="text" placeholder="Store Code" value={storeCode} onChange={e => {setStoreCode(e.target.value); setStoreCodeManuallyChanged(1);}} />
                                        {(checkStoreCodeAvailabilityResponse != null && checkStoreCodeAvailabilityResponse.status == 1) &&
                                            <div className='d-flex'>
                                                <div className='p-0'><CheckCircle className='text-success' style={{'width':'14px'}} /></div>
                                                <div className="col-form-label text-success p-0" style={{'margin':'2px 3px'}}>{checkStoreCodeAvailabilityResponse.message}</div>
                                            </div>
                                        }
                                        {(checkStoreCodeAvailabilityResponse != null && checkStoreCodeAvailabilityResponse.code == 404)  &&
                                            <div className='d-flex'>
                                                <div className='p-0'><XCircle className='text-danger' style={{'width':'14px'}} /></div>
                                                <div className="col-form-label text-danger p-0" style={{'margin':'2px 3px'}}>{checkStoreCodeAvailabilityResponse.message}</div>
                                            </div>
                                        }
                                        {(checkStoreCodeAvailabilityResponse != null && checkStoreCodeAvailabilityResponse.status == -1)  &&
                                            <span className="col-form-label text-info">{"Checking store code availability..."}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-6 pr-3">
                                        <label className="col-form-label pt-0">Mobile*</label>
                                        <PhoneInput defaultCountry="IN" placeholder="Mobile" value={phone} onChange={setPhone} error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
                                    </div>
                                    <div className="form-group col-sm-12 col-md-6 pr-3">
                                        <label className="col-form-label">{"Email (Optional)"}</label>
                                        <input className="form-control" type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                
                                {/* <div className="form-group">
                                    <label className="col-form-label">{"Accept Order In Currency"}</label>
                                    <Select Select mode="multiple" defaultValue={['INR']} style={{ width: "100%" }}>
                                        {Object.keys(sys_app_cnf.currency_code_to_symbol).map((currency,i) =>
                                            <Select.Option value={currency} key={i} selected>{`${currency} (${sys_app_cnf.currency_code_to_symbol[currency]})`} </Select.Option>
                                        )}
                                    </Select>
                                </div> */}

                                <label className="col-form-label">{"Store Address"}</label>
                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-6 pr-3">
                                        <Select
                                            value={selectedCountryOption}
                                            onChange={setSelectedCountryOption}
                                            options={countrySelectOption}
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 col-md-6 pr-3">
                                        <Select
                                            value={selectedStateOption}
                                            onChange={setSelectedStateOption}
                                            options={stateSelectOption}
                                        />
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-6 pr-3">
                                        <label className="col-form-label">{"Building Address"}</label>
                                        <input className="form-control" type="text" placeholder="Building Address" value={buildingAddress} onChange={e => setBuildingAddress(e.target.value)} />
                                    </div>
                                    
                                    <div className="form-group col-sm-12 col-md-6 pr-3">
                                        <label className="col-form-label">{"Street Address"}</label>
                                        <input className="form-control" type="text" placeholder="Street Address" value={streetAddress} onChange={e => setStreetAddress(e.target.value)} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-sm-12 col-md-6 pr-3">
                                        <label className="col-form-label">{"City/Town"}</label>
                                        <input className="form-control" type="text" placeholder="City/Town" value={city} onChange={e => {setCity(e.target.value); setCityManuallyChanged(1)}} />
                                    </div>
                                    <div className="form-group col-sm-12 col-md-6 pr-3">
                                        <label className="col-form-label">{"Postal/Zip Code"}</label>
                                        <input className="form-control" type="text" placeholder="Postal/Zip Code" value={postalCode} onChange={e => {setPostalCode(e.target.value); setPostalCodeManuallyChanged(1)}} />
                                    </div>
                                </div>

                                <div className="form-group col-sm-12 pr-3">
                                    <label className="col-form-label">{"Description (Seo)"}</label>
                                    <textarea rows={3} maxLength={150} className="form-control" placeholder="Description" value={description} onChange={e => {setDescription(e.target.value);}} />
                                </div>
                                
                                <div className="form-group form-row mt-3 mb-0 d-grid w-150">
                                    <button className="btn btn-primary" type="button" onClick={() => createStore()}>Create Store</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default StoreCreate;
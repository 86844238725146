import sys_cnf from '../../../system/config/config.js';
import sys_app_cnf from '../../../system/config/app-config.js';
import sysColor from '../../../system/config/color.js';
import React, { useState, useEffect, Fragment, CSSProperties } from 'react';
import getCurrentLine from 'get-current-line';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import getUser from '../../../api/auth/getUser.js';
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import cap1stLetter from '../../../system/method/cap1stLetter.js';
import capStr from '../../../system/method/capStr.js';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { v4 as uuid } from 'uuid'
import { Link, useNavigate} from 'react-router-dom';

const RoleList = () => {
    const navigate = useNavigate();
    var GetUser = getUser();
    const [RoleListResponse, setRoleListResponse] = useState(null);
    const [confirmDeletionModal, setConfirmDeletionModal] = useState(false);
    const [deletionRoleName, setDeletionRoleName] = useState("");
    const [deletionRoleId, setDeletionRoleId] = useState("");
    const [deletionConfirmValidationInput, setDeletionConfirmValidationInput] = useState();
    const [deletionConfirm1stNumber, setDeletionConfirm1stNumber] = useState(Math.floor(Math.random() * 10) + 1);
    const [deletionConfirm2ndNumber, setDeletionConfirm2ndNumber] = useState(Math.floor(Math.random() * 10) + 1);
    const [deletionConfirmErrorMessage, setDeletionConfirmErrorMessage] = useState();

    const toggleConfirmDeletionModal = () => {
        setConfirmDeletionModal(!confirmDeletionModal)
    }

    useEffect(() => {
        document.title = 'Roles - '+sys_app_cnf.site_name;
    }, []);

    const getRoleList = async () => {
        const sys_config = sys_cnf();
        setRoleListResponse({'status':-1,'message':'Loading roles...','data':{},'code':400});
        var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
        var sys_error = sys_respose.status == 0?0:0;
        if(sys_error == 0){
            var dataResponse;
            await axios.get(`${sys_config.api_req_domain}/api/admin/store/role/search?store_id=${GetUser.data.store_id}`, {
                headers: {
                    'Authorization': `Bearer ${GetUser.data.lid}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(function (response) {
                dataResponse = response.data;
            })
            .catch(function (error) {
                sys_error = 1;
                sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':error.response.data.code,'ev':getCurrentLine().line}
                setRoleListResponse(sys_respose);
            });
        }
        
        if(sys_error == 0){
            
            var tmpRoleList = [];
            for (const [key, value] of Object.entries(dataResponse.data.store_roles)) {
                var tmpRoleListItem = value;
                var actionButton = <div>
                    <span onClick={() => {setDeletionRoleId(value.uid); setDeletionRoleName(capStr(value.role));}}><i className="fa fa-trash text-danger" style={{ width: 35, fontSize: 16, padding: 11, color: sysColor.primary, cursor:'pointer' }}></i></span>
                    { (value.role != 'root' && value.role != 'superadmin' && value.role != 'admin') && <span><Link to={`/role/update?id=`+value.uid}><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: sysColor.primary, cursor:'pointer' }}></i></Link></span>}
                </div>
                tmpRoleListItem.action = actionButton;
                tmpRoleList.push(tmpRoleListItem);
            }
            sys_respose = {'status':dataResponse.status,'message':dataResponse.message,'data':tmpRoleList,'code':dataResponse.code};
            setRoleListResponse(sys_respose);
        }
    }

    const confirmDeletion = async () => {
        toggleConfirmDeletionModal();
    }

    useEffect(() => {
        setDeletionConfirmErrorMessage();
    }, [deletionConfirm1stNumber,deletionConfirm2ndNumber]);

    const deleteRoleProcess = async () => {
        if(parseInt(deletionConfirm1stNumber)+parseInt(deletionConfirm2ndNumber) == parseInt(deletionConfirmValidationInput)){
            deleteRole();
        }else{
            setDeletionConfirmErrorMessage("Please enter correct output to delete the role")
        }
    }

    const deleteRole = async () => {
        toggleConfirmDeletionModal();
        const sys_config = sys_cnf();
        var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
        var sys_error = sys_respose.status == 0?0:0;
        var tmpRoleId = deletionRoleId;
        var tmpRoleName = deletionRoleName;
        toast.loading(`Deleting ${tmpRoleName}...`, {toastId: tmpRoleId});
        if(sys_error == 0){
            var dataResponse;
            await axios.post(`${sys_config.api_req_domain}/api/admin/store/role/delete`,{
                role_id:tmpRoleId,
            }, {
                headers: {
                    'Authorization': `Bearer ${GetUser.data.lid}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(function (response) {
                dataResponse = response.data;
            })
            .catch(function (error) {
                sys_error = 1;
                sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':error.response.data.code,'ev':getCurrentLine().line}
                toast.update(tmpRoleId, { render: error.response.data.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            });
        }
        
        if(sys_error == 0){
            if(dataResponse.status == 1){
                toast.update(tmpRoleId, { render: dataResponse.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
                var tmpRoles = [];
                for (const [key, value] of Object.entries(RoleListResponse.data)) {
                    
                    if(value.uid != tmpRoleId){
                        tmpRoles[key] = value;
                        tmpRoles[key].no = parseInt(key)+1;
                    }
                }

                if(tmpRoles.length == 0){
                    navigate(`${process.env.PUBLIC_URL}/role/create`);
                }else{
                    sys_respose = {'status':1,'message':'Role list','data':tmpRoles,'code':200}
                    setRoleListResponse(sys_respose)

                    if(GetUser.data.role_id == tmpRoleId){
                        GetUser = getUser();
                    }
                }
            }else{
                toast.update(tmpRoleId, { render: dataResponse.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }
        }
    }

    useEffect(() => {
        if((deletionRoleId != null && deletionRoleId.length > 0) && (deletionRoleName != null && deletionRoleName.length > 0)){
            confirmDeletion(deletionRoleId,deletionRoleName);
            setDeletionConfirm1stNumber(Math.floor(Math.random() * 10) + 1);
            setDeletionConfirm2ndNumber(Math.floor(Math.random() * 10) + 1);
        }
    }, [deletionRoleId]);

    useEffect(() => {
        if(confirmDeletionModal != null && confirmDeletionModal == false){
            setDeletionRoleId(null);
            setDeletionRoleName(null);
        }
    }, [confirmDeletionModal]);
    
    var tableColumns = [
        {
            name: "Status",
            selector: (row) => cap1stLetter(row.status),
            sortable: false,
            center: true,
        },
        {
          name: "Role",
          selector: (row) => capStr(row.role),
          sortable: true,
          center: true,
        },
        {
            name: "",
            selector: (row) => row.action,
            sortable: true,
            center: true,
          },
    ];

    useEffect(() => {
        getRoleList();
    }, []);

    return (
        <Fragment>
            {/* <Breadcrumb title="New Role" parent="Role" /> */}
            {(RoleListResponse != null && RoleListResponse.status == 1) &&
                <Modal isOpen={confirmDeletionModal} toggle={toggleConfirmDeletionModal} className="modal-body" centered={true} size="sm">
                    <ModalHeader toggle={toggleConfirmDeletionModal}>{`Delete "${deletionRoleName}"`}</ModalHeader>
                    <ModalBody>
                        <div className="d-flex">
                            <h5 style={{whiteSpace: 'nowrap', marginRight: '11px'}}>{`${deletionConfirm1stNumber} + ${deletionConfirm2ndNumber} = `}</h5>
                            <input style={{marginTop: '-2px', maxWidth: '120px', height:'28px'}} className="form-control" type="text" placeholder="Enter output" onChange={e => setDeletionConfirmValidationInput(e.target.value)} />
                        </div>
                        {setDeletionConfirmErrorMessage != null &&
                            <div className="text-danger">{deletionConfirmErrorMessage}</div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleConfirmDeletionModal}>{"Cancel"}</Button>
                        <Button color="primary" onClick={deleteRoleProcess}>{"Delete"}</Button>
                    </ModalFooter>
                </Modal>
            }
            <div className="container-fluid row m-auto">
                <div className="col-sm-12 p-0">
                    <div className="card mt-4 p-4">
                        <div className="row">
                            <h4 className="text-left col-sm-6" style={{maxWidth:'50%'}}>My Roles</h4>
                            <Link className="col-sm-6" style={{maxWidth:'50%' }} to={`${process.env.PUBLIC_URL}/role/create`}>
                                <button className="btn btn-primary" style={{float: 'right'}} type="button">{"New Role"}</button>
                            </Link>
                        </div>
                        <div className="theme-form" style={{"marginTop":"30px"}}>
                            <div className="form-group col-sm-12 pl-3">
                                {(RoleListResponse != null && RoleListResponse.status == 1) &&
                                    <div className="table-responsive product-table">
                                        <DataTable
                                            noHeader
                                            columns={tableColumns}
                                            data={RoleListResponse.data}
                                        />
                                    </div>
                                }
                                {(RoleListResponse != null && RoleListResponse.status == 0) &&
                                    <div className="form-group col-sm-12 pr-3">
                                        <label className="col-form-label">{RoleListResponse.message}</label>
                                    </div>
                                }
                                {(RoleListResponse == null || RoleListResponse.status == -1) &&
                                    <div className="col-sm-12 pr-3">
                                        <BarLoader
                                            color={sysColor.primary}
                                            loading={true}
                                            width={'100%'}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default RoleList;
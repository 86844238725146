import sys_cnf from '../../system/config/config.js';
import getCurrentLine from 'get-current-line';
import axios from 'axios';
import saveUserDetail from './saveUserDetail.js';

let signupInit = async function(fname, lname, email, password, password_confirmation) {
    const sys_config = sys_cnf();
    var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
    var sys_error = 0;
    
    try {
        if(sys_error == 0){
            var dataResponse;
            await axios.post(`${sys_config.api_req_domain}/api/user/auth/register`, {
                'fname':fname,
                'lname':lname,
                'email':email,
                'password':password,
                'password_confirmation':password_confirmation,
                'app_id': sys_config.isLocalhost == 1?sys_config.localhost_app_id:'',
            },{
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
              })
              .then(function (response) {
                dataResponse = response.data;
              })
              .catch(function (error) {
                sys_error = 1;
                sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':400,'ev':getCurrentLine().line}
              });
        }
        
        if(sys_error == 0){
            sys_respose = {'status':dataResponse.status,'message':dataResponse.message,'data':dataResponse.data,'code':dataResponse.code};
        }

    } catch (error) {
        sys_error = 1;
        sys_respose = {'status':0,'message':'Something went wrong','data':{},'code':400,'ev':getCurrentLine().line};
    }

    return sys_respose;
};

export default signupInit;
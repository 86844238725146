import getTheme from '../../api/app/setting/getTheme.js';
import sys_cnf from "../../system/config/config.js";
const sys_config = sys_cnf();

const color = {
    'main':sys_config.partner_id == 'bharatgpt.io'?'#ffffff':'#ffffff',
    'primary':sys_config.partner_id == 'bharatgpt.io'?'#138808':'#1b74e4',
    'secondary':sys_config.partner_id == 'bharatgpt.io'?'#b4d1fd':'#b4d1fd',
    'secondary2':sys_config.partner_id == 'bharatgpt.io'?'#d1e7ff':'#d1e7ff',
    'red1':sys_config.partner_id == 'bharatgpt.io'?'#91262f':'#91262f',
    'redAccent':sys_config.partner_id == 'bharatgpt.io'?'#FF5252':'#FF5252',
    'lightGrey':sys_config.partner_id == 'bharatgpt.io'?'#dee0df':'#dee0df',
    'lightGrey2':sys_config.partner_id == 'bharatgpt.io'?'#dee0df':'#dee0df',
    'black':sys_config.partner_id == 'bharatgpt.io'?'#000000':'#000000',
    'white':sys_config.partner_id == 'bharatgpt.io'?'#ffffff':'#ffffff',
};
export default color
import sys_cnf from '../../../system/config/config.js';
import sys_app_cnf from '../../../system/config/app-config.js';
import sysColor from '../../../system/config/color.js';
import React, { useState, useEffect, Fragment, CSSProperties } from 'react';
import getCurrentLine from 'get-current-line';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import getUser from '../../../api/auth/getUser.js';
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import cap1stLetter from '../../../system/method/cap1stLetter.js';
import capStr from '../../../system/method/capStr.js';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { v4 as uuid } from 'uuid'
import { Link, useNavigate} from 'react-router-dom';

const MyStore = () => {
    const navigate = useNavigate();
    var GetUser = getUser();
    const [myStoresResponse, setMyStoresResponse] = useState(null);
    const [confirmDeletionModal, setConfirmDeletionModal] = useState(false);
    const [deletionStoreName, setDeletionStoreName] = useState("");
    const [deletionStoreId, setDeletionStoreId] = useState("");
    const [deletionConfirmValidationInput, setDeletionConfirmValidationInput] = useState();
    const [deletionConfirm1stNumber, setDeletionConfirm1stNumber] = useState(Math.floor(Math.random() * 10) + 1);
    const [deletionConfirm2ndNumber, setDeletionConfirm2ndNumber] = useState(Math.floor(Math.random() * 10) + 1);
    const [deletionConfirmErrorMessage, setDeletionConfirmErrorMessage] = useState();

    const toggleConfirmDeletionModal = () => {
        setConfirmDeletionModal(!confirmDeletionModal)
    }

    useEffect(() => {
        document.title = 'My Stores - '+sys_app_cnf.site_name;
    }, []);

    const getMyStores = async () => {
        const sys_config = sys_cnf();
        setMyStoresResponse({'status':-1,'message':'Loading stores...','data':{},'code':400});
        var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
        var sys_error = sys_respose.status == 0?0:0;
        if(sys_error == 0){
            var dataResponse;
            await axios.get(`${sys_config.api_req_domain}/api/admin/store/store/get-my-store`, {
                headers: {
                    'Authorization': `Bearer ${GetUser.data.lid}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(function (response) {
                dataResponse = response.data;
            })
            .catch(function (error) {
                sys_error = 1;
                sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':error.response.data.code,'ev':getCurrentLine().line}
                setMyStoresResponse(sys_respose);
            });
        }
        
        if(sys_error == 0){
            sys_respose = {'status':dataResponse.status,'message':dataResponse.message,'data':dataResponse.data,'code':dataResponse.code};
            for (const [key, value] of Object.entries(dataResponse.data)) {
                var actionButton = <div>
                    <span onClick={() => {setDeletionStoreId(value.uid); setDeletionStoreName(value.name);}}><i className="fa fa-trash text-danger" style={{ width: 35, fontSize: 16, padding: 11, color: sysColor.primary, cursor:'pointer' }}></i></span>
                    <span><Link to={`/store/update?id=`+value.uid}><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: sysColor.primary, cursor:'pointer' }}></i></Link></span>
                </div>
                dataResponse.data[key].no = parseInt(key)+1;
                dataResponse.data[key].logo = <img src={value.logo_image} style={{ width: 60, maxHeight: 35 }} alt="" />;
                dataResponse.data[key].action = actionButton;
            }
            setMyStoresResponse(sys_respose);
        }
    }

    const confirmDeletion = async () => {
        toggleConfirmDeletionModal();
    }

    useEffect(() => {
        setDeletionConfirmErrorMessage();
    }, [deletionConfirm1stNumber,deletionConfirm2ndNumber]);

    const deleteStoreProcess = async () => {
        if(parseInt(deletionConfirm1stNumber)+parseInt(deletionConfirm2ndNumber) == parseInt(deletionConfirmValidationInput)){
            deleteStore();
        }else{
            setDeletionConfirmErrorMessage("Please enter correct output to delete the store")
        }
    }

    const deleteStore = async () => {
        toggleConfirmDeletionModal();
        const sys_config = sys_cnf();
        var sys_respose = {'status':0,'message':'','data':{},'code':400,'ev':getCurrentLine().line}
        var sys_error = sys_respose.status == 0?0:0;
        var tmpStoreId = deletionStoreId;
        var tmpStoreName = deletionStoreName;
        toast.loading(`Deleting ${tmpStoreName}...`, {toastId: tmpStoreId});
        if(sys_error == 0){
            var dataResponse;
            await axios.post(`${sys_config.api_req_domain}/api/admin/store/store/delete`,{
                store_id:tmpStoreId,
            }, {
                headers: {
                    'Authorization': `Bearer ${GetUser.data.lid}`,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(function (response) {
                dataResponse = response.data;
            })
            .catch(function (error) {
                sys_error = 1;
                sys_respose = {'status':0,'message':error.response.data.message,'data':{},'code':error.response.data.code,'ev':getCurrentLine().line}
                toast.update(tmpStoreId, { render: error.response.data.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            });
        }
        
        if(sys_error == 0){
            if(dataResponse.status == 1){
                toast.update(tmpStoreId, { render: dataResponse.message, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
                var tmpStores = [];
                for (const [key, value] of Object.entries(myStoresResponse.data)) {
                    
                    if(value.uid != tmpStoreId){
                        tmpStores[key] = value;
                        tmpStores[key].no = parseInt(key)+1;
                    }
                }

                if(tmpStores.length == 0){
                    localStorage.removeItem("store_id");
                    localStorage.removeItem("store");
                    navigate(`${process.env.PUBLIC_URL}/store/create`);
                }else{
                    sys_respose = {'status':1,'message':'Store list','data':tmpStores,'code':200}
                    setMyStoresResponse(sys_respose)

                    if(GetUser.data.store_id == tmpStoreId){
                        localStorage.setItem("store_id",tmpStores[0].uid);
                        localStorage.setItem("store",JSON.stringify(tmpStores[0]));
                        GetUser = getUser();
                    }
                }
            }else{
                toast.update(tmpStoreId, { render: dataResponse.message, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnHover: false, pauseOnFocusLoss: false });
            }
        }
    }

    useEffect(() => {
        if((deletionStoreId != null && deletionStoreId.length > 0) && (deletionStoreName != null && deletionStoreName.length > 0)){
            confirmDeletion(deletionStoreId,deletionStoreName);
            setDeletionConfirm1stNumber(Math.floor(Math.random() * 10) + 1);
            setDeletionConfirm2ndNumber(Math.floor(Math.random() * 10) + 1);
        }
    }, [deletionStoreId]);

    useEffect(() => {
        if(confirmDeletionModal != null && confirmDeletionModal == false){
            setDeletionStoreId(null);
            setDeletionStoreName(null);
        }
    }, [confirmDeletionModal]);
    
    var tableColumns = [
        {
            name: "Status",
            selector: (row) => cap1stLetter(row.status),
            sortable: false,
            center: true,
        },
        {
            name: "Logo",
            selector: (row) => row.logo,
            sortable: true,
            center: true,
        },
        {
          name: "Name",
          selector: (row) => capStr(row.name),
          sortable: true,
          center: true,
        },
        {
          name: "Code",
          selector: (row) => row.store_code,
          sortable: true,
          center: true,
        },
        {
            name: "",
            selector: (row) => row.action,
            sortable: true,
            center: true,
          },
    ];

    useEffect(() => {
        getMyStores();
    }, []);

    return (
        <Fragment>
            {/* <Breadcrumb title="New Store" parent="Store" /> */}
            {(myStoresResponse != null && myStoresResponse.status == 1) &&
                <Modal isOpen={confirmDeletionModal} toggle={toggleConfirmDeletionModal} className="modal-body" centered={true} size="sm">
                    <ModalHeader toggle={toggleConfirmDeletionModal}>{`Delete "${deletionStoreName}"`}</ModalHeader>
                    <ModalBody>
                        <div className="d-flex">
                            <h5 style={{whiteSpace: 'nowrap', marginRight: '11px'}}>{`${deletionConfirm1stNumber} + ${deletionConfirm2ndNumber} = `}</h5>
                            <input style={{marginTop: '-2px', maxWidth: '120px', height:'28px'}} className="form-control" type="text" placeholder="Enter output" onChange={e => setDeletionConfirmValidationInput(e.target.value)} />
                        </div>
                        {setDeletionConfirmErrorMessage != null &&
                            <div className="text-danger">{deletionConfirmErrorMessage}</div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleConfirmDeletionModal}>{"Cancel"}</Button>
                        <Button color="primary" onClick={deleteStoreProcess}>{"Delete"}</Button>
                    </ModalFooter>
                </Modal>
            }
            <div className="container-fluid row m-auto">
                <div className="col-sm-12 p-0">
                    <div className="card mt-4 p-4">
                        <div className="row">
                            <h4 className="text-left col-sm-6" style={{maxWidth:'50%'}}>My Stores</h4>
                            <Link className="col-sm-6" style={{maxWidth:'50%' }} to={`${process.env.PUBLIC_URL}/store/create`}>
                                <button className="btn btn-primary" style={{float: 'right'}} type="button">{"New Store"}</button>
                            </Link>
                        </div>
                        <div className="theme-form" style={{"marginTop":"30px"}}>
                            <div className="form-group col-sm-12 pl-3">
                                {(myStoresResponse != null && myStoresResponse.status == 1) &&
                                    <div className="table-responsive product-table">
                                        <DataTable
                                            noHeader
                                            columns={tableColumns}
                                            data={myStoresResponse.data}
                                        />
                                    </div>
                                }
                                {(myStoresResponse != null && myStoresResponse.status == 0) &&
                                    <div className="form-group col-sm-12 pr-3">
                                        <label className="col-form-label">{myStoresResponse.message}</label>
                                    </div>
                                }
                                {(myStoresResponse == null || myStoresResponse.status == -1) &&
                                    <div className="col-sm-12 pr-3">
                                        <BarLoader
                                            color={sysColor.primary}
                                            loading={true}
                                            width={'100%'}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MyStore;